import { constants, instances } from '../store';

export const inView = (el, render) => {
  const interval = setInterval(() => {
    if (instances.scroll && constants.pageEntranceFinished) {
      clearInterval(interval);

      new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            instances.time.emitter.on('tick', render);
          } else {
            instances.time.emitter.off('tick', render);
          }
        });
      }).observe(el);
    }
  });
};

export const intersectRatio = (val, el) => {
  const min = el.offsetTop - window.innerHeight;
  const max = el.offsetTop + el.offsetHeight;

  return ((val - min) * 100) / (max - min);
};

export const intersectMinMax = (val, el, min, max) => {
  const percentage = intersectRatio(val, el);

  return ((percentage - 0) / (100 - 0)) * (max - min) + min;
};
