import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { CustomEase } from 'gsap/CustomEase';

import { constants } from '../store';

CustomEase.create('ease-short-in-long-out', 'M0,0 C0.35,0 0.42,1 1,1');
CustomEase.create('secondaire-text-ease', 'M0,0 C0.42,0 0.3,1 1,1  ');
CustomEase.create('secondaire-text-ease-2', 'M0,0 C0.14,0.5 0.34,1 1,1 ');
CustomEase.create('page-transition-e2', 'M0,0 C0.24,0 0.27,1 1,1');
CustomEase.create('main-text-ease', 'M0,0 C0.22,1 0.36,1 1,1 ');
CustomEase.create('intro-scale', 'M0,0 C0.37,0 0.2,1 1,1');

const tl = gsap.timeline({
  paused: true,
  onComplete: () => {
    document.body.classList.remove('is-transitioning');
    constants.pageEntranceFinished = true;
  }
});

gsap.registerPlugin(SplitText, CustomEase);

const setHomeHeaderAnim = (dom) => {
  dom.body = dom.header.querySelector('.js-body');
  dom.button = dom.header.querySelector('.js-button');
  dom.imgWrap = dom.header.querySelector('.js-img-wrap');
  dom.img = dom.imgWrap.querySelector('.js-img');
  dom.overlay = dom.header.querySelector('.js-overlay');

  tl
    .to(dom.overlay, { autoAlpha: 1, scale: 1, duration: 1.45, ease: 'intro-scale' }, 'start+=.2')
    .to([dom.body, dom.button], { autoAlpha: 1, duration: 0.85 }, 'start+=0.7');

  tl.play();
};

const setFluidHeaderAnim = (dom) => {
  dom.overlay = dom.header.querySelector('.js-overlay');

  tl.to(dom.overlay, { autoAlpha: 1, duration: 0.87, ease: 'in-out-smooth' }, 'start');

  tl.play();
};

const setContainedHeaderAnim = () => {
  tl.play();
};

const setImageVideoHeaderAnim = (dom) => {
  dom.body = dom.header.querySelector('.js-body');
  dom.playButton = dom.header.querySelectorAll('.js-play-button');

  dom.overlay = dom.header.querySelector('.js-overlay');

  tl.to(dom.overlay, { autoAlpha: 1, scale: 1, duration: 1.45, ease: 'intro-scale' }, 'start+=.2');

  if (dom.body) {
    tl.to(dom.body, { autoAlpha: 1, duration: 0.85 }, 'start+=0.7');
  }

  if (dom.playButton) {
    tl.to(dom.playButton, { autoAlpha: 1, duration: 0.85 }, 'start+=0.7');
  }

  tl.play();
};

const setContactAnim = () => {
  tl.play();
};

export const pageEntrance = (page) => {
  const dom = {};

  // global
  dom.page = page;

  // nav
  dom.nav = document.body.querySelector('.js-nav');
  dom.navItems = dom.nav.querySelectorAll('.js-item');
  dom.navLinks = dom.nav.querySelectorAll('.js-link');
  dom.button = dom.nav.querySelector('.js-button');
  dom.buttonOuterCircle = dom.button.querySelector('.js-outer-circle > ellipse');
  dom.buttonBurger = dom.button.querySelectorAll('.js-path');

  // header
  dom.header = document.body.querySelector('.js-header');

  if (!dom.header) {
    document.body.classList.remove('is-transitioning');
    constants.pageEntranceFinished = true;
  }

  if (dom.header) {
    dom.chapeau = dom.header.querySelector('.js-chapeau');
    if (dom.chapeau) { dom.chapeauLine = new SplitText(dom.chapeau, { type: 'lines' }).lines; }
    dom.heading = dom.header.querySelector('.js-heading');
    dom.headingLines = new SplitText(dom.heading, { type: 'lines', linesClass: 'header__heading-wrap js-heading-wrap' }).lines;
  }

  tl
    .addLabel('start');

  // global
  switch (constants.headerLayout) {
    case 'home':
    case 'image-video':
      if (!constants.showPreloader) {
        tl.set([dom.page, dom.nav], { y: 450 });
      }

      break;

    default:
      tl.set([dom.page, dom.nav], { y: 450 });
      break;
  }

  tl
    // nav
    .set(dom.navLinks, { y: dom.navLinks[0].offsetHeight })
    .set(dom.buttonOuterCircle, { strokeDashoffset: dom.buttonOuterCircle.getTotalLength(), strokeDasharray: dom.buttonOuterCircle.getTotalLength() })
    .set(dom.buttonBurger, { strokeDashoffset: dom.buttonBurger[0].getTotalLength(), strokeDasharray: dom.buttonBurger[0].getTotalLength() })
    .set([dom.navItems], { autoAlpha: 1 })
    .set([dom.navItems], { autoAlpha: 1 });

  // Header
  if (dom.header) {
    if (dom.chapeauLine) {
      tl.set(dom.chapeauLine, { y: dom.chapeau.offsetHeight });
    }

    [...dom.headingLines].forEach((line) => {
      const headingChars = new SplitText(line, { type: 'chars', charsClass: 'js-char' }).chars;

      tl
        .set(headingChars, { y: headingChars[0].offsetHeight });
    });

    if (dom.chapeau) {
      tl.set([dom.chapeau, dom.heading], { autoAlpha: 1 });
    } else {
      tl.set(dom.heading, { autoAlpha: 1 });
    }
  }

  // global
  tl
    .to([dom.page, dom.nav], { y: 0, scale: 1, duration: 1.09, ease: 'page-transition-e2' }, 'start')

    // nav
    .to(dom.navLinks, { y: 0, duration: 0.85, ease: 'secondaire-text-ease' }, 'start+=0.32');

  tl
    .to(dom.button, { autoAlpha: 1, duration: 0.85, ease: 'secondaire-text-ease' }, 'start+=0.32')
    .to(dom.buttonOuterCircle, { strokeDashoffset: 0, duration: 0.58, ease: 'secondaire-text-ease' }, 'start+=0.15')
    .to(dom.buttonBurger, { strokeDashoffset: 0, duration: 0.58, stagger: 0.05, ease: 'secondaire-text-ease' }, 'start+=0.15');

  // Header
  if (dom.header) {
    if (dom.chapeauLine) {
      tl.to(dom.chapeauLine, { y: 0, duration: 0.85, ease: 'secondaire-text-ease-2' }, 'start+=0.58');
    }

    [...dom.headingLines].forEach((line) => {
      const headingChars = line.querySelectorAll('.js-char');

      tl.to(headingChars, { y: 0, duration: 1.12, stagger: 0.015, ease: 'main-text-ease' }, 'start+=0.53');
    });
  } else {
    tl.play();
  }

  switch (constants.headerLayout) {
    case 'home':
      setHomeHeaderAnim(dom);
      break;

    case 'image-fluid':
      setFluidHeaderAnim(dom);
      break;

    case 'image-contained':
      setContainedHeaderAnim();
      break;

    case 'image-video':
      setImageVideoHeaderAnim(dom);
      break;

    case 'contact':
      setContactAnim();
      break;

    default:
      break;
  }
};
