import { constants, instances } from '../../store';
import { intersectMinMax } from '../../utils/in-viewport';
import { inView } from '../../utils/inView';

class DoubleImage {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;
    this.dom.cols = this.dom.el.querySelectorAll('.js-col');
  }

  render = () => {
    const yRight = intersectMinMax(Math.abs(instances.scroll.state.last) + this.dom.el.offsetHeight, this.dom.el, -120, 60, false);
    this.dom.cols[1].style.transform = `translateY(${yRight}px)`;
  }

  init() {
    if (constants.isDevice) return;

    inView(this.dom.el, this.render);
  }

  destroy() {
    instances.time.emitter.off('tick', this.render);
  }
}

export default DoubleImage;
