import gsap from 'gsap';
import { constants, instances } from '../../store';
import { intersectMinMax } from '../../utils/in-viewport';
import { inView } from '../../utils/inView';

class Grid {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;
    this.dom.imgWraps = el.querySelectorAll('.js-img-wrap');
  }

  setCache() {
    this.images = [];

    [...this.dom.imgWraps].forEach((wrap) => {
      const img = wrap.querySelector('.js-img');

      this.images.push({
        img,
        yOffset: Math.abs(wrap.offsetHeight - img.offsetHeight)
      });
    });
  }

  yPos = (min, max) => {
    const y = intersectMinMax(Math.abs(instances.scroll.state.last), this.dom.el, min, max, false);

    return y;
  }

  render = () => {
    if (this.dom.imgWraps[0]) {
      this.dom.imgWraps[0].style.transform = `translateY(${this.yPos(0, (window.innerWidth / 12) * 0.5)}px)`;
    }

    if (this.dom.imgWraps[2]) {
      this.dom.imgWraps[2].style.transform = `translateY(${this.yPos(0, -(window.innerWidth / 12))}px)`;
    }

    this.images.forEach((el) => {
      gsap.set(el.img, {
        y: this.yPos(-(window.innerWidth / 12) / 2, 0)
      });
    });
  }

  init() {
    if (constants.isDevice) return;

    let i = 0;

    [...this.dom.imgWraps].forEach((item) => {
      const interval = setInterval(() => {
        if (item.querySelector('.js-img')) {
          clearInterval(interval);

          i += 1;
        }
      });
    });

    const interval = setInterval(() => {
      if (this.dom.imgWraps.length === i) {
        clearInterval(interval);

        this.dom.images = this.dom.el.querySelectorAll('.js-img');

        this.setCache();

        inView(this.dom.el, this.render);
      }
    });
  }

  destroy() {
    instances.time.emitter.off('tick', this.render);
  }
}

export default Grid;
