import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { constants, instances } from '../../store';

import { lerp, pxVw } from '../../utils/math';

gsap.registerPlugin(CustomEase);

CustomEase.create('cirkel-ease', 'M0,0 C0.5,0 0.2,1 1,1 ');

class Panorama {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;
    this.dom.textImage = el.classList.contains('js-text-image-panorama');
    this.dom.wrap = this.dom.el.querySelector('.js-wrap');
    this.dom.close = this.dom.el.querySelector('.js-close');
    this.dom.overlay = this.dom.el.querySelector('.js-drag-overlay');

    this.dom.nav = document.querySelector('.js-nav');
    this.dom.register = document.querySelector('.js-register-cta');
    this.dom.openButton = document.querySelector('.js-button-open-register');

    this.dom.app = document.querySelector('.js-app');

    this.dom.backToTop = document.querySelector('.js-back-to-top');

    this.state = {
      open: false,
      closedWidth: this.dom.wrap.getBoundingClientRect().width,
      last: 0,
      mousePos: 0,
      widthDifference: 0,
      wrapperWidth: this.dom.wrap.getBoundingClientRect().width,
      top: 0,
      animating: false,
      url: this.dom.el.getAttribute('data-imgurl'),
      imgWidth: 0,
      imgHeight: 0,
      ratio: false,
    };
  }

  toggleImage = (e) => {
    if (this.state.animating) return;
    this.state.animating = true;

    if (!this.state.open) {
      this.state.open = true;

      this.dom.el.closest('section').style.zIndex = '99';

      if (!constants.isDevice) instances.scroll.disable();
      else this.dom.app.style.overflow = 'hidden';

      this.state.top = (this.dom.wrap.getBoundingClientRect().y > 0) ? -Math.abs(this.dom.wrap.getBoundingClientRect().y) : Math.abs(this.dom.wrap.getBoundingClientRect().y);

      this.state.last = e.clientX / window.innerWidth;
      this.state.mousePos = e.clientX / window.innerWidth;

      if (this.dom.textImage && !constants.isDevice) {
        const position = this.dom.el.dataset.position ?? 'right';

        gsap.to(this.dom.el, {
          [position]: 0,
          duration: 1.41,
          ease: 'cirkel-ease',
        });
      }

      gsap.to(this.dom.wrap, {
        width: '100vw',
        height: '100vh',
        backgroundSize: this.dom.el.dataset.orientation === 'is_portrait' ? 'auto 100%' : '100%',
        y: this.state.top,
        duration: 1.41,
        ease: 'cirkel-ease',
        onComplete: () => {
          this.state.animating = false;

          this.dom.wrap.setAttribute('data-cursor', 'move');

          if (!this.state.ratio) {
            if (!constants.isDevice) {
              window.addEventListener('mousemove', this.handleMouseMove);
            } else {
              window.addEventListener('touchmove', this.handleMouseMove);
              window.addEventListener('touchstart', this.removeDragMessage);
            }

            instances.time.emitter.on('tick', this.render);
          }
        }
      });

      gsap.to([this.dom.close, this.dom.overlay], {
        autoAlpha: 1,
        duration: 0.705,
        ease: 'cirkel-ease',
        delay: 1.41
      });

      if (!this.state.ratio) {
        gsap.fromTo(this.dom.wrap, {
          backgroundPosition: '50% 50%'
        }, {
          backgroundPosition: `${this.state.mousePos * 100}% 50%`,
          duration: 1.41,
          ease: 'cirkel-ease'
        });
      }

      gsap.to([this.dom.nav, this.dom.backToTop, this.dom.register, this.dom.openButton], {
        autoAlpha: 0,
        duration: 1,
        ease: 'cirkel-ease'
      });
    } else {
      if (constants.isDevice && e.target.classList.contains('js-wrap')) return;

      this.dom.el.closest('section').style.zIndex = null;

      this.state.open = false;

      window.removeEventListener('mousemove', this.handleMouseMove);
      instances.time.emitter.off('tick', this.render);

      if (this.dom.textImage) {
        const position = this.dom.el.dataset.position ?? 'right';

        gsap.to(this.dom.el, { [position]: `${pxVw(1440, 120)}vw`, duration: 1.41, ease: 'cirkel-ease' });

        const width = this.dom.el.dataset.orientation === 'is_portrait' ? pxVw(1440, 605) : pxVw(1440, 720);

        gsap.to(this.dom.wrap, {
          width: `${(!constants.isDevice) ? width : pxVw(360, 300)}vw`,
          height: !constants.isDevice ? '100%' : `${pxVw(360, 388)}vw`,
          backgroundSize: this.dom.el.dataset.orientation === 'is_portrait' ? 'auto 100%' : '100%',
          backgroundPosition: '50% 50%',
          y: 0,
          duration: 1.41,
          ease: 'cirkel-ease',
          onComplete: () => {
            this.state.animating = false;

            if (!constants.isDevice) {
              instances.scroll.enable();
              this.dom.wrap.setAttribute('data-cursor', 'explore');
            } else {
              this.dom.app.style.overflow = null;
            }
          }
        });
      } else {
        gsap.to(this.dom.wrap, {
          width: `${(!constants.isDevice) ? pxVw(1440, 840) : pxVw(360, 240)}vw`,
          height: `${(!constants.isDevice) ? pxVw(1440, 900) : pxVw(360, 253)}vw`,
          backgroundSize: this.dom.el.dataset.orientation === 'is_portrait' ? 'auto 100%' : '100%',
          backgroundPosition: '50% 50%',
          y: 0,
          duration: 1.41,
          ease: 'cirkel-ease',
          onComplete: () => {
            this.state.animating = false;

            if (!constants.isDevice) {
              instances.scroll.enable();
              this.dom.wrap.setAttribute('data-cursor', 'explore');
            } else {
              this.dom.app.style.overflow = null;
            }
          }
        });
      }

      gsap.to([this.dom.nav, this.dom.backToTop, this.dom.register, this.dom.openButton], {
        autoAlpha: 1,
        duration: 1,
        ease: 'cirkel-ease'
      });

      gsap.killTweensOf(this.dom.overlay);

      gsap.to([this.dom.close, this.dom.overlay], {
        autoAlpha: 0,
        duration: 1.41,
        ease: 'cirkel-ease',
      });
    }
  }

  handleMouseMove = (e) => {
    if (constants.isDevice) {
      this.state.mousePos = e.touches[0].clientX / window.innerWidth;
    } else {
      this.state.mousePos = e.clientX / window.innerWidth;
    }
  }

  removeDragMessage = () => {
    if (this.state.open) {
      gsap.to(this.dom.overlay, {
        autoAlpha: 0,
        duration: 1.41,
        ease: 'cirkel-ease',
        delay: 0.2,
      });
    }
  }

  render = () => {
    this.state.last = lerp(this.state.last, this.state.mousePos, 0.07);

    gsap.set(this.dom.wrap, {
      backgroundPosition: `${this.state.last * 100}% 50%`,
    });
  }

  addEventListeners = () => {
    this.dom.wrap.addEventListener('click', this.toggleImage);

    if (constants.isDevice) {
      this.dom.close.addEventListener('click', this.toggleImage);
    }
  }

  setCache = () => {
    const img = new Image();
    img.src = this.state.url;

    img.onload = () => {
      this.state.imgWidth = img.naturalWidth;
      this.state.imgHeight = img.naturalHeight;

      this.state.ratio = this.state.imgWidth / this.state.imgHeight < window.innerWidth / window.innerHeight;

      if (this.state.ratio) {
        this.dom.close.style.stroke = '#000';
      }
    };
  }

  init() {
    this.addEventListeners();

    this.setCache();
  }

  destroy() {
    this.removeEventListeners();
  }
}

export default Panorama;
