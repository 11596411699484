import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

gsap.registerPlugin(CustomEase);

CustomEase.create('button-vlak-scale', 'M0,0 C0.5,0 0.21,1 1,1 ');

class Button {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;

    this.state = {
      video: null,
    };
  }

  handleFilmButton = () => {
    // create a video element
    if (this.state.video === null) {
      this.state.video = document.createElement('video');
      this.state.video.style.width = '0';
      this.state.video.style.height = '0';
      this.state.video.src = this.dom.el.dataset.film;

      // append video to the body and make it full screen
      document.body.appendChild(this.state.video);
    }

    // play the video
    // Mag ook een array zijn met alle prefixes
    if (this.state.video.requestFullscreen) {
      this.state.video.requestFullscreen();
      // @ts-ignore
    } else if (this.state.video.mozRequestFullscreen) {
      // @ts-ignore
      this.state.video.mozRequestFullscreen();
      // @ts-ignore
    } else if (this.state.video.webkitRequestFullscreen) {
      // @ts-ignore
      this.state.video.webkitRequestFullscreen();
      // @ts-ignore
    } else if (this.state.video.webkitEnterFullScreen) {
      // @ts-ignore
      this.state.video.webkitEnterFullScreen();
      // @ts-ignore
    } else if (this.state.video.msRequestFullscreen) {
      // @ts-ignore
      this.state.video.msRequestFullscreen();
    }

    this.state.video.play();
  }

  handleCloseFullScreen = () => {
    if (!this.state.video) return;

    // @ts-ignore
    if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      this.state.video.pause();
    }
  };

  addListeners() {
    this.dom.el.addEventListener('click', this.handleFilmButton);

    const prefixes = ['', 'moz', 'webkit', 'ms'];

    prefixes.forEach((prefix) => {
      document.addEventListener(`${prefix}fullscreenchange`, this.handleCloseFullScreen);
    });
  }

  removeListeners() {
    this.dom.el.removeEventListener('click', this.handleFilmButton);

    const prefixes = ['', 'moz', 'webkit', 'ms'];

    prefixes.forEach((prefix) => {
      document.removeEventListener(`${prefix}fullscreenchange`, this.handleCloseFullScreen);
    });
  }

  init() {
    if (this.dom.el.dataset) {
      this.addListeners();
    }
  }

  destroy() {
    if (this.dom.el.dataset) {
      this.removeListeners();
    }
  }
}

export default Button;
