import gsap from 'gsap';
import { constants } from '../../store';

class RegisterCTA {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-register-cta');
    this.dom.button = this.dom.el.querySelector('.js-button');
    this.dom.openButton = document.querySelector('.js-button-open-register');

    this.dom.hero = document.querySelector('.js-header');

    this.observer = undefined;
  }

  revealCta = () => {
    this.observer = new IntersectionObserver(([entry]) => {
      if (!entry.isIntersecting) {
        if (!constants.registerCTA) {
          this.hideCta();
        } else {
          this.showCta();
        }

        this.observer.unobserve(this.dom.hero);
      }
    });

    this.observer.observe(this.dom.hero);
  }

  hideCta = () => {
    constants.registerCTA = false;

    gsap.to(this.dom.el, { autoAlpha: 0, duration: 0.5, });
    gsap.to(this.dom.openButton, { autoAlpha: 1, duration: 0.5 });
  }

  showCta = () => {
    constants.registerCTA = true;

    gsap.to(this.dom.el, { autoAlpha: 1, duration: 0.5 });
    gsap.to(this.dom.openButton, { autoAlpha: 0, duration: 0.5 });
  }

  addClickEvent = () => {
    this.dom.button.addEventListener('click', () => { this.hideCta(); });
    this.dom.openButton.addEventListener('click', () => { this.showCta(); });
  }

  init() {
    this.addClickEvent();
    this.revealCta();
  }

  destroy() {
    this.observer.unobserve(this.dom.hero);

    gsap.to([this.dom.el, this.dom.openButton], { autoAlpha: 0, duration: 0.5, });
  }
}

export default RegisterCTA;
