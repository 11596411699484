import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { constants } from '../../store';

gsap.registerPlugin(CustomEase);

CustomEase.create('soft-ease', 'M0,0 C0.17,0 0.44,1 1,1 ');

class Features {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;
    this.dom.items = this.dom.el.querySelectorAll('.js-item');
    this.dom.imgWraps = this.dom.el.querySelectorAll('.js-img-wrap');
    this.dom.imgs = [];
  }

  hoverIn = (i) => {
    const imgWrap = this.dom.imgWraps[i];
    const img = this.dom.imgs[i];
    const item = this.dom.items[i];

    const title = item.querySelector('.js-title');
    const description = item.querySelector('.js-description');

    gsap.killTweensOf([imgWrap, img, title, description]);

    gsap.set(imgWrap, { y: -(img.offsetHeight - item.offsetHeight) / 2, scale: 0, });
    gsap.set(img, { scale: 1.5 });

    gsap.to(title, { x: 20, duration: 0.49, ease: 'soft-ease' });
    gsap.to(description, { x: -20, duration: 0.49, ease: 'soft-ease' });

    gsap.to([imgWrap, img], { scale: 1, autoAlpha: 1, duration: 1.04, ease: 'soft-ease', });
  }

  hoverOut = (i) => {
    const imgWrap = this.dom.imgWraps[i];
    const img = this.dom.imgs[i];
    const item = this.dom.items[i];

    const title = item.querySelector('.js-title');
    const description = item.querySelector('.js-description');

    gsap.killTweensOf([imgWrap, img, title, description]);

    gsap.to([title, description], { x: 0, duration: 0.49, ease: 'soft-ease' });

    gsap.to(imgWrap, { scale: 0, autoAlpha: 0, duration: 0.49, ease: 'soft-ease', });
    gsap.to(img, { scale: 0.5 });
  }

  addListeners() {
    [...this.dom.items].forEach((el, i) => { el.addEventListener('mouseenter', () => { this.hoverIn(i); }); });
    [...this.dom.items].forEach((el, i) => { el.addEventListener('mouseleave', () => { this.hoverOut(i); }); });
  }

  removeListeners() {
    [...this.dom.items].forEach((el, i) => { el.removeEventListener('mouseenter', () => { this.hoverIn(i); }); });
    [...this.dom.items].forEach((el, i) => { el.removeEventListener('mouseleave', () => { this.hoverOut(i); }); });
  }

  init() {
    if (constants.isDevice) return;

    let i = 0;

    [...this.dom.imgWraps].forEach((item) => {
      const interval = setInterval(() => {
        if (item.querySelector('.js-img')) {
          clearInterval(interval);

          const img = item.querySelector('.js-img');

          i += 1;

          this.dom.imgs.push(img);
        }
      });
    });

    const interval = setInterval(() => {
      if (this.dom.imgWraps.length === i) {
        clearInterval(interval);

        this.addListeners();
      }
    });
  }

  destroy() {
    this.removeListeners();
  }
}

export default Features;
