import { constants, instances } from '../../store';
import { intersectMinMax } from '../../utils/in-viewport';
import { inView } from '../../utils/inView';

class CTA {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;
    this.dom.imgWrap = this.dom.el.querySelector('.js-wrap');
  }

  render = () => {
    const y = intersectMinMax(Math.abs(instances.scroll.state.last), this.dom.el, -(window.innerWidth / 12), 0, false);

    this.dom.img.style.transform = `translateY(${y}px)`;
  }

  init() {
    if (constants.isDevice) return;

    const interval = setInterval(() => {
      if (this.dom.imgWrap.querySelector('.js-img')) {
        clearInterval(interval);

        this.dom.img = this.dom.imgWrap.querySelector('.js-img');

        inView(this.dom.el, this.render);
      }
    });
  }

  destroy() {
    instances.time.emitter.off('tick', this.render);
  }
}

export default CTA;
