import Highway from '@dogstudio/highway';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

import { constants, instances } from '../store';
import { criticalImagesLoaded } from '../utils/critical-images-loaded';
import { pageEntrance } from '../utils/page-entrance-anims';

gsap.registerPlugin(CustomEase);

CustomEase.create('page-transition-e', 'M0,0 C0.45,0 0.58,1 1,1 ');
CustomEase.create('page-transition-e2', 'M0,0 C0.24,0 0.27,1 1,1');
CustomEase.create('weak-in-out-ease', 'M0,0 C0.45,0 0.58,1 1,1 ');

const transitionEl = document.querySelector('.js-transition');
const nav = document.querySelector('.js-nav');

class DefaultTransition extends Highway.Transition {
  out({ from, done }) {
    const header = from.querySelector('.js-header');
    const fluidImages = document.querySelectorAll('[data-image="fluid"]');
    const registerCTA = document.querySelector('.js-register-cta');

    const tl = gsap.timeline({ paused: true });

    const { top } = from.getBoundingClientRect();
    const height = (top >= 0) ? window.innerHeight / 2 : Math.abs(top);

    tl
      .addLabel('start')
      .set(transitionEl, { autoAlpha: 1, yPercent: 100 })
      .to(transitionEl, { yPercent: 0, duration: 0.6, ease: 'page-transition-e', onComplete: ()=> {
        if (constants.isDevice) {
          document.querySelector('.js-app').scrollTop = 0;
        }
      } }, 'start')
      .to(registerCTA, { autoAlpha: 0, duration: 0.5 }, 'start');

    if (!instances.menu.state.open) {
      if (fluidImages.length > 0) {
        [...fluidImages].forEach((wrap) => {
          wrap.style.overflow = 'visible';
          header.style.overflow = 'visible';

          tl.to(wrap, { scale: 1 / 0.9, duration: 0.6, transformOrigin: `${window.innerWidth / 2}px ${height}px`, ease: 'page-transition-e2' }, 'start');
        });
      }

      tl
        .to([from, nav], { y: -450, duration: 0.6, scale: 0.9, transformOrigin: `${window.innerWidth / 2}px ${height}px`, ease: 'page-transition-e2', onComplete: () => done() }, 'start');
    } else {
      tl.to(instances.menu.state.el, { y: -450, duration: 0.6, transformOrigin: 'center center', ease: 'page-transition-e2', onComplete: () => done(), clearProps: 'all' }, 'start+=0.1');

      instances.menu.state.open = false;
      instances.menu.state.done = true;
      instances.menu = undefined;
    }

    tl.play();
  }

  in({ to, from, done }) {
    const tl = gsap.timeline({ paused: true });

    const header = to.querySelector('.js-header');
    const { theme } = header.dataset;

    const navigation = document.querySelector('.js-nav');

    if (header.dataset.nav === 'light') {
      navigation.classList.remove('is-dark');
    } else {
      navigation.classList.add('is-dark');
    }

    if (theme === 'light') {
      document.body.classList.remove('is-dark');
    } else {
      document.body.classList.add('is-dark');
    }

    from.remove();

    tl
      .to(transitionEl, { yPercent: -100, duration: 0.7, ease: 'page-transition-e' }, 'start');

    constants.headerLayout = to.querySelector('.js-header').dataset.layout;
    constants.transitionDuration = tl.duration();

    criticalImagesLoaded(() => {
      tl.play();
      setTimeout(() => pageEntrance(to), tl.duration() * 1000 - 700);
    });

    done();

    instances.scroll.destroy();
    instances.scroll = undefined;
  }
}

export default DefaultTransition;
