import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { constants } from '../../store';

gsap.registerPlugin(CustomEase);
CustomEase.create('cirkel-ease', 'M0,0 C0.5,0 0.2,1 1,1 ');

class Cursor {
  constructor() {
    this.dom = {};
    this.dom.sections = document.querySelectorAll('[data-cursor]');

    this.state = {
      close: false,
      inCarousel: false,
    };

    this.cursor = {
      outer: undefined,
      inner: undefined,
      innerSecondary: undefined,
      text: undefined,
      textSecondary: undefined,
      current: undefined,
      last: undefined,
    };

    this.mouse = {
      x: 0,
      y: 0
    };
  }

  initNewSections = () => {
    this.removeEventListeners();

    setTimeout(() => {
      this.dom.sections = document.querySelectorAll('[data-cursor]');
      this.addEventListeners();
    });
  }

  createCursor() {
    this.cursor.outer = document.createElement('div');
    this.cursor.outer.classList.add('cursor');
    document.body.appendChild(this.cursor.outer);

    // Primary text
    this.cursor.inner = document.createElement('div');
    this.cursor.inner.classList.add('cursor__inner');
    this.cursor.outer.appendChild(this.cursor.inner);

    this.cursor.text = document.createElement('span');
    this.cursor.text.classList.add('cursor__text');
    this.cursor.inner.appendChild(this.cursor.text);

    // Secondary text
    this.cursor.innerSecondary = document.createElement('div');
    this.cursor.innerSecondary.classList.add('cursor__inner--secondary');
    this.cursor.outer.appendChild(this.cursor.innerSecondary);

    this.cursor.textSecondary = document.createElement('span');
    this.cursor.textSecondary.classList.add('cursor__text--secondary');
    this.cursor.innerSecondary.appendChild(this.cursor.textSecondary);
  }

  handleMouseMove = (e) => {
    this.mouse = {
      x: e.clientX - this.cursor.outer.offsetWidth / 2,
      y: e.clientY - this.cursor.outer.offsetWidth / 2,
    };

    this.cursor.outer.style.transform = `translate(${this.mouse.x}px, ${this.mouse.y}px)`;
  }

  toggleCursor = (e) => {
    const { cursor } = e.target.dataset;

    switch (cursor) {
      case 'explore':
      case 'move': {
        this.cursor.text.innerText = 'explore';
        this.cursor.textSecondary.innerText = 'move / close';
        gsap.set(this.cursor.textSecondary, { autoAlpha: 0, xPercent: 0 });
        gsap.set(this.cursor.textSecondary, { xPercent: 0 });

        if (e.target.closest('.is-white')) {
          this.cursor.outer.classList.add('is-dark');
        }
        break;
      }

      case 'click': {
        this.cursor.text.innerText = 'read more';
        this.cursor.textSecondary.innerText = 'read more';
        break;
      }

      case 'drag': {
        this.cursor.text.innerText = 'drag';
        this.cursor.textSecondary.innerText = 'drag';
        break;
      }

      case 'pause': {
        this.cursor.text.innerText = 'pause';
        this.cursor.textSecondary.innerText = 'pause';
        break;
      }

      case 'next': {
        if (this.cursor.last === 'prev') {
          gsap.to(this.cursor.text, { xPercent: 0, duration: 0.2, ease: 'cirkel-ease' });
          gsap.to(this.cursor.textSecondary, { xPercent: 100, duration: 0.2, ease: 'cirkel-ease' });
        } else {
          this.cursor.outer.classList.add('cursor__carousel');
          this.cursor.text.innerText = 'next';
          this.cursor.textSecondary.innerText = 'previous';

          gsap.set(this.cursor.text, { xPercent: 0 });
          gsap.set(this.cursor.textSecondary, { xPercent: 100, autoAlpha: 1 });
        }
        break;
      }

      case 'prev': {
        if (this.cursor.last === 'next') {
          gsap.to(this.cursor.text, { xPercent: -100, duration: 0.2, ease: 'cirkel-ease' });
          gsap.to(this.cursor.textSecondary, { xPercent: 0, duration: 0.2, ease: 'cirkel-ease' });
        } else {
          this.cursor.outer.classList.add('cursor__carousel');
          this.cursor.text.innerText = 'next';
          this.cursor.textSecondary.innerText = 'previous';

          gsap.set(this.cursor.text, { xPercent: -100 });
          gsap.set(this.cursor.textSecondary, { xPercent: 0, autoAlpha: 1 });
        }
        break;
      }

      default: {
        break;
      }
    }

    if (e.type === 'mouseenter') {
      if (e.target.dataset.cursor !== 'carousel') {
        gsap.killTweensOf([this.cursor.inner, this.cursor.innerSecondary]);

        gsap.to([this.cursor.inner, this.cursor.innerSecondary], { scale: 1, duration: 0.4, ease: 'cirkel-ease' });
      } else {
        this.state.inCarousel = true;
      }
    } else if (e.type === 'mouseleave') {
      this.cursor.last = cursor;

      if (e.target.dataset.cursor === 'carousel') {
        this.state.inCarousel = false;
        this.cursor.outer.classList.remove('cursor__carousel');
      }

      if (e.target.dataset.cursor === 'explore' && this.cursor.outer.classList.contains('is-dark')) {
        this.cursor.outer.classList.remove('is-dark');
      }

      if (this.state.inCarousel) return;

      gsap.killTweensOf([this.cursor.inner, this.cursor.innerSecondary, this.cursor.text, this.cursor.textSecondary]);

      gsap.to([this.cursor.inner, this.cursor.innerSecondary], { scale: 0, duration: 0.4, ease: 'cirkel-ease', onComplete: () => {
        this.cursor.last = undefined;
      }, clearProps: 'all' });
    }
  }

  handleMouseClick = (e) => {
    const { cursor } = e.target.dataset;

    switch (cursor) {
      case 'explore': {
        gsap.to(this.cursor.inner, { autoAlpha: 0, scale: 0.8, duration: 0.54, ease: 'cirkel-ease' });
        gsap.to(this.cursor.innerSecondary, { scale: 0.8, duration: 0.54, ease: 'cirkel-ease' });
        gsap.to(this.cursor.textSecondary, { autoAlpha: 1, duration: 0.54, ease: 'cirkel-ease' });

        if (this.cursor.outer.classList.contains('is-dark')) {
          gsap.to(this.cursor.innerSecondary, { borderColor: '#000', duration: 0.54, ease: 'cirkel-ease' });
          gsap.to(this.cursor.textSecondary, { color: '#000', duration: 0.54, ease: 'cirkel-ease' });
        }
        break;
      }

      case 'move': {
        gsap.to(this.cursor.inner, { autoAlpha: 1, scale: 1, duration: 0.54, ease: 'cirkel-ease' });
        gsap.to(this.cursor.innerSecondary, { scale: 1, duration: 0.54, ease: 'cirkel-ease' });
        gsap.to(this.cursor.textSecondary, { autoAlpha: 0, duration: 0.54, ease: 'cirkel-ease' });

        if (this.cursor.outer.classList.contains('is-dark')) {
          gsap.to(this.cursor.innerSecondary, { borderColor: '#fff', duration: 0.54, ease: 'cirkel-ease', clearProps: 'all' });
          gsap.to(this.cursor.textSecondary, { color: '#fff', duration: 0.54, ease: 'cirkel-ease', clearProps: 'all' });
        }
        break;
      }

      case 'next':
      case 'prev': {
        gsap.to(this.cursor.inner, { autoAlpha: 1, scale: 0.9, duration: 0.27, ease: 'cirkel-ease' });
        gsap.to(this.cursor.innerSecondary, { scale: 0.9, duration: 0.27, ease: 'cirkel-ease' });
        gsap.to(this.cursor.inner, { autoAlpha: 1, scale: 1, duration: 0.27, ease: 'cirkel-ease', delay: 0.27 });
        gsap.to(this.cursor.innerSecondary, { scale: 1, duration: 0.27, ease: 'cirkel-ease', delay: 0.27 });
        break;
      }

      case 'pause': {
        gsap.to(this.cursor.inner, { autoAlpha: 1, scale: 0.9, duration: 0.27, ease: 'cirkel-ease' });
        gsap.to(this.cursor.innerSecondary, { scale: 0.9, duration: 0.27, ease: 'cirkel-ease' });
        gsap.to(this.cursor.inner, { autoAlpha: 1, scale: 1, duration: 0.27, ease: 'cirkel-ease', delay: 0.27 });
        gsap.to(this.cursor.innerSecondary, { scale: 1, duration: 0.27, ease: 'cirkel-ease', delay: 0.27 });

        this.cursor.text.innerText = 'play';
        this.cursor.textSecondary.innerText = 'play';
        break;
      }

      case 'play': {
        gsap.to(this.cursor.inner, { autoAlpha: 1, scale: 0.9, duration: 0.27, ease: 'cirkel-ease' });
        gsap.to(this.cursor.innerSecondary, { scale: 0.9, duration: 0.27, ease: 'cirkel-ease' });
        gsap.to(this.cursor.inner, { autoAlpha: 1, scale: 1, duration: 0.27, ease: 'cirkel-ease', delay: 0.27 });
        gsap.to(this.cursor.innerSecondary, { scale: 1, duration: 0.27, ease: 'cirkel-ease', delay: 0.27 });

        this.cursor.text.innerText = 'pause';
        this.cursor.textSecondary.innerText = 'pause';
        break;
      }

      default: {
        break;
      }
    }
  }

  addEventListeners() {
    [...this.dom.sections].forEach((section) => {
      section.addEventListener('mouseenter', this.toggleCursor);
      section.addEventListener('mouseleave', this.toggleCursor);

      section.addEventListener('click', this.handleMouseClick);
    });

    window.addEventListener('mousemove', this.handleMouseMove);
  }

  removeEventListeners() {
    [...this.dom.sections].forEach((section) => {
      section.removeEventListener('mouseenter', this.toggleCursor);
      section.removeEventListener('mouseleave', this.toggleCursor);

      section.removeEventListener('click', this.handleMouseClick);
    });

    window.removeEventListener('mousemove', this.handleMouseMove);
  }

  init() {
    if (constants.isDevice) return;

    this.createCursor();
    this.addEventListeners();
  }

  destroy() {
    if (constants.isDevice) return;

    this.cursor.outer.remove();
    this.removeEventListeners();
  }
}

export default Cursor;
