import { gsap } from 'gsap';
import CustomEase from 'gsap/CustomEase';
import { constants } from '../../store';

gsap.registerPlugin(CustomEase);

CustomEase.create('ease-short-in-long-out', 'M0,0 C0.35,0 0.42,1 1,1');
CustomEase.create('intro-ease', 'M0,0 C0.6,0 0.4,1 1,1');
CustomEase.create('intro-scale', 'M0,0 C0.37,0 0.2,1 1,1');

import { pageEntrance } from '../../utils/page-entrance-anims';

class Preloader {
  constructor(page) {
    this.dom = {};
    this.dom.el = document.querySelector('.js-preloader');
    this.dom.inner = this.dom.el.querySelector('.js-preloader-inner');
    this.dom.page = page;

    this.dom.header = document.querySelector('.js-header');

    if (this.dom.header) {
      this.dom.headerWrap = this.dom.header.querySelector('.js-img-wrap');
      this.dom.video = this.dom.header.querySelector('.js-video');
    }

    this.state = {
      animationCompleted: false
    };
  }

  animate(animType) {
    const tl = gsap.timeline({ paused: true, onComplete: () => { this.state.animationCompleted = true; } });

    if (animType === 'image') {
      tl
        .addLabel('start')
        .to(this.dom.el, { yPercent: -100, ease: 'intro-ease', duration: 1.83 }, 'start')
        .to(this.dom.inner, { y: '100%', ease: 'intro-ease', duration: 1.83 }, 'start')
        .to(this.dom.inner, { borderTop: !constants.isDevice ? 10 : 0, borderLeft: !constants.isDevice ? 10 : 0, borderRight: !constants.isDevice ? 10 : 0, borderBottom: 0, ease: 'intro-scale', duration: 1.32 }, 'start+=1.83');
      if (this.dom.img) {
        tl
          .to(this.dom.img, { scale: 0.85, duration: 1.83, ease: 'intro-ease', }, 'start')
          .to(this.dom.img, { scale: 1, y: 0, duration: 1.32, ease: 'intro-scale', }, 'start+=1.83');
      } else if (this.dom.video) {
        tl
          .to(this.dom.video, { scale: 0.85, duration: 1.83, ease: 'intro-ease', }, 'start')
          .to(this.dom.video, { scale: 1, y: 0, duration: 1.32, ease: 'intro-scale', }, 'start+=1.83');
      }

      setTimeout(() => pageEntrance(this.dom.page), 1830);
    } else {
      tl
        .to(this.dom.el, { yPercent: -100, duration: 1.09, ease: 'ease-short-in-long-out', });

      setTimeout(() => pageEntrance(this.dom.page), tl.duration() * 1000 - 1090);
    }

    tl.play();

    const intervalDone = setInterval(() => {
      if (this.state.animationCompleted) {
        clearInterval(intervalDone);

        constants.showPreloader = false;

        this.destroy();
      }
    });
  }

  init() {
    switch (constants.headerLayout) {
      case 'image-video':
      case 'home': {
        const interval = setInterval(() => {
          if (this.dom.headerWrap.querySelector('.js-img')) {
            clearInterval(interval);

            this.dom.img = this.dom.headerWrap.querySelector('.js-img');

            this.animate('image');
          } else if (this.dom.video) {
            clearInterval(interval);

            this.animate('image');
          }
        });

        break;
      }

      default: {
        this.dom.inner.remove();

        this.animate('default');
        break;
      }
    }
  }

  destroy() {
    this.dom.el.remove();
  }
}

export default Preloader;
