import gsap from 'gsap';
import VirtualScroll from 'virtual-scroll';

import { lerp } from './math';
import { constants, instances } from '../store';

class Scroll {
  constructor() {
    this.dom = {
      main: document.querySelector('main'),
      sections: document.querySelectorAll('[data-scroll]'),
      app: document.querySelector('.js-app'),
      backToTop: document.querySelector('.js-back-to-top'),
    };

    this.state = {
      last: 0,
      ease: 0.1,
      height: 0,
      current: 0,
      direction: 'up'
    };

    this.scrollContainers = this.dom.sections;
  }

  setVs() {
    this.vs = new VirtualScroll();
    this.vs.options.passive = true;
    this.vs.options.limitInertia = false;
    this.vs.options.touchMultiplier = 2.5;
    this.vs.options.mouseMultiplier = 0.45;
    this.vs.options.firefoxMultiplier = 100;

    this.vs.on(this.calc);
  }

  render = () => {
    this.state.last = lerp(this.state.last, this.state.current, this.state.ease);

    this.state.abs = Math.abs(this.state.last);
    if (this.state.abs < 0.1) this.state.last = 0;

    if (constants.isDevice) {
      this.state.current = this.dom.main.getBoundingClientRect().top;
      this.state.last = this.dom.main.getBoundingClientRect().top;
    } else {
      [...this.sections].forEach((section, index) => this.inViewport(index));
    }

    // set scroll direction
    if (this.state.last > this.state.current) this.state.direction = 'down';
    else this.state.direction = 'up';
  }

  calc = (e) => {
    this.state.current += e.deltaY;
    this.state.current = Math.max((this.state.height - window.innerHeight) * -1, this.state.current);
    this.state.current = Math.min(0, this.state.current);
  }

  setHeight = () => {
    if (this.scrollContainers === this.dom.sections) {
      this.state.height = this.dom.main.getBoundingClientRect().height;
    } else {
      const bounds = this.scrollContainers[0].getBoundingClientRect();
      this.state.height = bounds.height;
    }
  }

  setCache = (event = '') => {
    if (event === 'resize') {
      this.sections.forEach((section) => section.bounds = section.el.getBoundingClientRect());

      this.setHeight();
    } else {
      this.setHeight();

      this.sections = [];
      this.scrollContainers.forEach((el) => {
        const bounds = el.getBoundingClientRect();
        this.sections.push({ el, bounds, inView: false });
      });
    }
  }

  inViewport(index) {
    if (!this.sections) return;

    const cache = this.sections[index];
    const transform = Math.abs(this.state.last);

    const { top, height } = cache.bounds;
    const inView = transform >= top - window.innerHeight && transform < top + height;

    if (inView) {
      cache.el.style.transform = `translate3d(0, ${transform * -1}px, 0)`;
      cache.inView = true;
      cache.el.style.visibility = 'visible';
    } else {
      const resetTop = window.innerHeight + height;
      cache.el.style.transform = `translate3d(0, ${resetTop}px, 0)`;
      cache.inView = false;
      cache.el.style.visibility = 'hidden';
    }
  }

  handleResize = () => {
    [...this.dom.sections].forEach((section) => section.style.transform = 'translate3d(0, 0, 0)');

    this.setCache('resize');
    this.disable();
    this.enable();
  }

  backToTop = () => {
    this.state.current = 0;

    instances.nav.toggleNav();

    if (!constants.isDevice) return;

    this.dom.app.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  events() {
    window.addEventListener('resize', this.handleResize, { passive: true });
  }

  enable() {
    this.vs.on(this.calc);
    gsap.ticker.add(this.render);
  }

  disable() {
    this.vs.off();
    gsap.ticker.remove(this.render);
  }

  init() {
    const interval = setInterval(() => {
      if (constants.pageEntranceFinished) {
        clearInterval(interval);

        if (!constants.isDevice) {
          this.setVs();
          this.events();
          this.setCache();
        } else {
          constants.sectionsInView = this.dom.sections;
        }

        gsap.ticker.add(this.render);

        this.dom.backToTop.addEventListener('click', this.backToTop);
      }
    });
  }

  destroy() {
    gsap.ticker.remove(this.render);

    this.vs && this.vs.destroy();

    window.removeEventListener('resize', this.handleResize, { passive: true });
    this.dom.backToTop.addEremoveEventListenerventListener('click', this.backToTop);
  }
}

export default Scroll;
