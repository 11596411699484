import { constants, instances } from '../../store';
class EnergyCalculator {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;
    this.dom.button = this.dom.el.querySelector('.js-iframe-button');

    this.dom.app = document.querySelector('.js-app');
  }

  openIframe() {
    if (!constants.isDevice) {
      instances.scroll.disable();
    }

    const iframeOverlay = document.createElement('div');
    iframeOverlay.className = 'energy-calculator__overlay';
    iframeOverlay.style.opacity = 0;

    const iframeWrapper = document.createElement('div');
    iframeWrapper.className = 'energy-calculator__iframe-wrap';
    iframeOverlay.appendChild(iframeWrapper);

    const iframeClose = document.createElement('button');
    iframeClose.className = 'energy-calculator__close';

    iframeWrapper.appendChild(iframeClose);

    this.dom.iframe = document.createElement('iframe');
    this.dom.iframe.src = 'https://energiecalculator.yorem.nl/kondorwessels/amstelkwartier/?sToken=OXlvcmVNRW5lcnlDYWxjdWxhdG9y';
    this.dom.iframe.className = 'energy-calculator__iframe';
    this.dom.iframe.setAttribute('id', 'energieCalculator');
    iframeWrapper.appendChild(this.dom.iframe);

    this.dom.app.appendChild(iframeOverlay);

    setTimeout(() => {
      iframeOverlay.style.opacity = 1;
    }, 100);

    iframeClose.addEventListener('click', () => {
      iframeOverlay.style.opacity = 0;

      if (!constants.isDevice) {
        instances.scroll.enable();
      }

      setTimeout(() => {
        this.dom.app.removeChild(iframeOverlay);
      }, 600);
    });
  }

  addEventListeners() {
    this.dom.button.addEventListener('click', this.openIframe.bind(this));
  }

  removeEventListeners() {
    this.dom.button.removeEventListener('click', this.openIframe.bind(this));
  }

  init() {
    this.addEventListeners();
  }

  destroy() {
    this.removeEventListeners();
  }
}

export default EnergyCalculator;
