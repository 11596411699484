import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

gsap.registerPlugin(CustomEase);

CustomEase.create('ease-scale-down', 'M0,0 C0.42,0 0.3,1 1,1 ');

class Carousel {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;
    this.dom.buttons = el.querySelectorAll('.js-button');
    this.dom.carouselButtons = el.querySelectorAll('.js-carousel-button');
    this.dom.imgWraps = el.querySelectorAll('.js-img-wrap');

    this.state = {
      current: 0,
      next: undefined,
      prev: undefined
    };

    this.tl = gsap.timeline({ paused: true, onComplete: () => { this.state.animating = false; } });
  }

  setCache() {
    this.images = [];

    [...this.dom.imgWraps].forEach((el) => {
      this.images.push({
        wrap: el,
        img: el.querySelector('.js-img')
      });
    });
  }

  slide = (i) => {
    if (this.state.animating) return;
    this.state.animating = true;

    let current = this.images[this.state.current];

    if (i === 0) {
      if (this.state.current === this.dom.imgWraps.length - 1) {
        this.state.next = this.images[0];
        this.state.current = -1;
      } else {
        this.state.next = this.images[this.state.current + 1];
      }

      this.state.current += 1;

      this.tl
        .addLabel('start')
        .set(this.dom.imgWraps, { autoAlpha: 0 })
        .set(current.wrap, { zIndex: 1, autoAlpha: 1 })
        .set(this.state.next.wrap, { xPercent: -100, zIndex: 2, autoAlpha: 1 })
        .set(this.state.next.img, { xPercent: 50, scale: 1.2 })

        .to(current.wrap, { xPercent: 50, duration: 0.95, ease: 'ease-scale-down', clearProps: 'transform' }, 'start')
        .to([this.state.next.wrap, this.state.next.img], { xPercent: 0, duration: 0.95, ease: 'ease-scale-down' }, 'start')
        .to(this.state.next.img, { scale: 1, duration: 0.95, ease: 'ease-scale-down' }, 'start');

      this.tl.play();
    } else {
      if (this.state.current === 0) {
        this.state.prev = this.images[this.dom.imgWraps.length - 1];
        this.state.current = this.dom.imgWraps.length;
      } else {
        this.state.prev = this.images[this.state.current - 1];
      }

      this.state.current -= 1;

      [...this.dom.imgWraps].forEach((img) => img.style.zIndex = 0);

      this.tl
        .addLabel('start')
        .set(this.dom.imgWraps, { autoAlpha: 0 })
        .set(current.wrap, { zIndex: 1, autoAlpha: 1 })
        .set(this.state.prev.wrap, { xPercent: 100, zIndex: 2, autoAlpha: 1 })
        .set(this.state.prev.img, { xPercent: -50, scale: 1.2 })

        .to(current.wrap, { xPercent: -50, duration: 0.95, ease: 'ease-scale-down', clearProps: 'transform' }, 'start')
        .to([this.state.prev.wrap, this.state.prev.img], { xPercent: 0, duration: 0.95, ease: 'ease-scale-down' }, 'start')
        .to(this.state.prev.img, { scale: 1, duration: 0.95, ease: 'ease-scale-down' }, 'start');

      this.tl.play();
    }
  }

  addListeners() {
    [...this.dom.buttons].forEach((button, index) => { button.addEventListener('click', () => { this.slide(index); }); });
    [...this.dom.carouselButtons].forEach((button, index) => { button.addEventListener('click', () => { this.slide(index); }); });
  }

  removeListeners() {
    [...this.dom.buttons].forEach((button, index) => { button.removeEventListener('click', () => { this.slide(index); }); });
    [...this.dom.carouselButtons].forEach((button, index) => { button.removeEventListener('click', () => { this.slide(index); }); });
  }

  init() {
    let i = 0;

    [...this.dom.imgWraps].forEach((item) => {
      const interval = setInterval(() => {
        if (item.querySelector('.js-img')) {
          clearInterval(interval);

          i += 1;
        }
      });
    });

    const interval = setInterval(() => {
      if (this.dom.imgWraps.length === i) {
        clearInterval(interval);

        this.setCache();
        this.addListeners();
      }
    });
  }

  destroy() {
    this.removeListeners();
  }
}

export default Carousel;
