import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { inView } from '../../utils/inView';
import { lerp, pxVw } from '../../utils/math';
import { constants, instances } from '../../store';

gsap.registerPlugin(CustomEase);

CustomEase.create('image', 'M0,0 C0.37,0 0.2,1 1,1');
CustomEase.create('counter', 'M0,0 C0.51,0 0.37,1 1,1');
CustomEase.create('cijfers', 'M0,0 C0.36,0 0,1 1,1');

class Number {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;
    this.dom.list = el.querySelector('.js-list');
    this.dom.items = el.querySelectorAll('.js-item');
    this.dom.wrapper = el.querySelectorAll('.js-wrapper');
    this.dom.numbers = el.querySelectorAll('.js-number');

    this.state = {
      current: {
        x: 0,
        y: 0,
      },
      last: {
        x: 0,
        y: 0,
      },
      animating: false,
      active: false,
    };
  }

  createImage = () => {
    this.images = [];

    this.imgWrap = document.createElement('div');
    this.imgWrap.classList.add('numbers__img');

    [...this.dom.items].forEach((item) => {
      const mask = document.createElement('div');
      mask.classList.add('numbers__img-mask', 'js-mask');

      const wrap = document.createElement('div');
      wrap.classList.add('numbers__img-wrap');

      mask.appendChild(wrap);

      const img = document.createElement('img');
      img.classList.add('js-img');
      img.src = item.dataset.image;
      wrap.appendChild(img);

      this.images.push({ mask, src: item.dataset.image });
    });

    document.body.appendChild(this.imgWrap);
  }

  changeImage = (index) => {
    this.state.animating = true;

    const current = this.images[index];

    this.originalImage = this.imgWrap.querySelector('.js-mask');

    this.clone = current.mask.cloneNode(true);
    const cloneWrap = this.clone.querySelector('.numbers__img-wrap');
    this.cloneImg = this.clone.querySelector('img');

    this.imgWrap.appendChild(this.clone);

    gsap.set(this.clone, { yPercent: 100, autoAlpha: 1 });
    gsap.set(cloneWrap, { yPercent: -100, autoAlpha: 1 });
    gsap.set(this.cloneImg, { scale: 2 });

    if (this.originalImage) gsap.to(this.originalImage, { scale: 1, duration: 1.4, ease: 'image' });

    gsap.to([this.clone, cloneWrap], { yPercent: 0, duration: 1.4, ease: 'image' });
    gsap.to(this.cloneImg, { scale: 1.5, duration: 1.4, ease: 'image' });
  }

  mousePos = (e) => {
    this.state.current = {
      x: e.clientX + 30,
      y: e.clientY - (this.imgWrap.offsetHeight / 2),
    };
  }

  render = () => {
    this.state.last = {
      x: lerp(this.state.last.x, this.state.current.x, 0.15),
      y: lerp(this.state.last.y, this.state.current.y, 0.15)
    };

    this.imgWrap.style.transform = `translate(${this.state.last.x}px, ${this.state.last.y}px)`;

    // Delete img clones
    if ([...this.imgWrap.querySelectorAll('.js-mask')].some((clone) => clone.style.transform !== 'translate(0px, 0px)' || !this.state.animating)) return;

    this.state.animating = false;

    [...this.imgWrap.querySelectorAll('.js-mask')].forEach((clone) => {
      clone.remove();

      if (this.originalImage) this.originalImage.remove();

      this.imgWrap.appendChild(this.clone);
    });
  }

  toggleImage = (e) => {
    this.state.active = (e.type === 'mouseenter');
    gsap.to(this.imgWrap, { autoAlpha: (e.type === 'mouseenter') ? 1 : 0 });
  }

  createNumbers = () => {
    [...this.dom.numbers].map((item, i) => {
      const numbers = item.innerHTML.split('');
      const allNumbers = [];

      numbers.forEach((number) => {
        const check = (num) => {
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(num) && num !== 0) return '.';
          if (num === -1) return 9;
          if (num === -2) return 8;
          return num;
        };

        const createNumberEl = (value, off) => {
          allNumbers.push('<div class="numbers__number js-num" />');
          [...Array(3)].map((_empty, index) => allNumbers.push(`<div>${check(value - off[index])}</div>`));
          allNumbers.push('</div>');
        };

        if (number === '1') {
          createNumberEl(1, [0, 0, 0]);
        } else {
          createNumberEl(number, [0, 1, 2]);
        }
      });

      item.innerHTML = allNumbers.join('');

      this.dom.wrapper[i].style.height = `${pxVw(window.innerWidth, item.offsetHeight)}vw`;
    });
  }

  numberAnim = () => {
    const interval = setInterval(() => {
      if (instances.scroll) {
        clearInterval(interval);

        [...this.dom.numbers].forEach((number, index) => {
          let animated = false;

          new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting && !animated) {
                animated = true;

                gsap.to(this.dom.items[index], { autoAlpha: 1, duration: 0.3, ease: 'counter' });

                gsap.fromTo(number.querySelectorAll('.js-num'),
                  { yPercent: -100 },
                  { yPercent: 0, stagger: 0.17, duration: 1.27, ease: 'cijfers' });
              }
            });
          }, {
            rootMargin: '90px'
          }).observe(number);
        });
      }
    });
  }

  addEventListeners = () => {
    window.addEventListener('mousemove', (e) => { this.mousePos(e); });
    this.dom.list.addEventListener('mouseenter', this.toggleImage);
    this.dom.list.addEventListener('mouseleave', this.toggleImage);
    [...this.dom.items].forEach((item, index) => item.addEventListener('mouseenter', () => { this.changeImage(index); }));
  }

  removeEventListeners = () => {
    window.removeEventListener('mousemove', (e) => { this.mousePos(e); });
    this.dom.list.removeEventListener('mouseenter', this.toggleImage);
    this.dom.list.removeEventListener('mouseleave', this.toggleImage);
    [...this.dom.items].forEach((item, index) => item.removeEventListener('mouseenter', () => { this.changeImage(index); }));
  }

  init() {
    this.createImage();
    this.createNumbers();
    this.numberAnim();

    if (!constants.isDevice) {
      this.addEventListeners();
    }

    inView(this.dom.el, this.render);
  }

  destroy() {
    this.imgWrap.remove();
    this.removeEventListeners();
    instances.time.emitter.off('tick', this.render);
  }
}

export default Number;
