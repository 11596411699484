import Scroll from '../utils/scroll';
import Time from '../utils/time';
import ImageLoader from '../utils/image-loader';
import ScrollBasedAnims from '../utils/scroll-based-anims';
import { pageEntrance } from '../utils/page-entrance-anims';
import { criticalImagesLoaded } from '../utils/critical-images-loaded';
import { constants, instances } from '../store';

import Preloader from '../components/preloader/preloader';
import Menu from '../components/menu/menu';
import Nav from '../components/nav/nav';
import RegisterCTA from '../components/register_cta/register_cta';

const globalEvents = (namespace = null) => {
  constants.namespace = namespace;

  if (window.innerWidth < 480) constants.size = 'mobile';
  else if (window.innerWidth >= 480 && window.innerWidth < 768) constants.size = 'tablet';
  else if (window.innerWidth >= 768 && window.innerWidth < 1180) constants.size = 'desktop-sm';
  else if (window.innerWidth >= 1180 && window.innerWidth < 1440) constants.size = 'desktop-md';
  else if (window.innerWidth >= 1440 && window.innerWidth < 1920) constants.size = 'desktop-lg';
  else constants.size = 'desktop-xl';

  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // load critical (above the fold) images first
  instances.imageLoader.loadCritical(constants.size, () => {
    const els = document.querySelectorAll('[data-load="lazy"]');

    [...els].forEach((el) => instances.imageLoader.load(el, constants.size));
  });

  criticalImagesLoaded(() => {
    instances.reigsterCTA = new RegisterCTA();
    instances.reigsterCTA.init();

    instances.nav = new Nav();
    instances.nav.init();

    instances.menu = new Menu();
    instances.menu.init();

    instances.scroll = new Scroll();
    instances.scroll.init();

    instances.scrollBasedAnims = new ScrollBasedAnims();
    instances.scrollBasedAnims.init();
  });
};

const onResize = () => {
  if (window.innerWidth < 480) constants.size = 'mobile';
  else if (window.innerWidth >= 480 && window.innerWidth < 768) constants.size = 'tablet';
  else constants.size = 'desktop';

  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export const onReady = () => {
  const view = document.querySelector('[data-router-view]');
  const namespace = view.dataset.routerView;

  // detect mobile device and set body class
  constants.isDevice = 'ontouchstart' in window;
  constants.isDevice && document.body.classList.add('is-device');

  constants.dpr = window.devicePixelRatio;

  const header = document.querySelector('.js-header');
  if (header) constants.headerLayout = header && header.dataset.layout;

  if (header) {
    const { theme, nav } = header.dataset;

    if (theme === 'light') {
      document.body.classList.remove('is-dark');

      document.querySelector('.nav').classList.remove('is-dark');
    } else {
      document.body.classList.add('is-dark');
      document.querySelector('.js-back-to-top').classList.add('is-dark');
    }

    if (nav === 'light') {
      document.querySelector('.nav').classList.remove('is-dark');
    } else {
      document.querySelector('.nav').classList.add('is-dark');
      document.querySelector('.js-back-to-top').classList.add('is-dark');
    }
  }

  instances.imageLoader = new ImageLoader();
  instances.time = new Time();

  globalEvents(namespace);

  criticalImagesLoaded(() => {
    if (constants.showPreloader) {
      instances.preloader = new Preloader(view);
      instances.preloader.init();
    } else {
      document.querySelector('.js-preloader').remove();
      pageEntrance(view);
    }
  });

  window.addEventListener('resize', onResize);
};

export const onLeave = () => {
  document.body.classList.add('is-transitioning');

  constants.criticalImagesLoaded = false;

  constants.pageEntranceFinished = false;

  instances.nav.destroy();
  instances.nav = undefined;

  instances.menu.destroy();

  instances.reigsterCTA.destroy();
};

export const onEnter = () => {};

export const onEnterCompleted = ({ to }) => {
  globalEvents(to.view.dataset.routerView);
};
