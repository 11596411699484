import { instances } from '../../store';
import { intersectMinMax } from '../../utils/in-viewport';
import { vwPx } from '../../utils/math';

import { gsap } from 'gsap';
import { inView } from '../../utils/inView';

class Image {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;
    this.dom.img = this.dom.el.querySelector('.js-img-wrap');
    this.dom.caption = this.dom.el.querySelector('.js-caption');
  }

  setCache() {
    let { x, height } = this.dom.img.getBoundingClientRect();
    x -= (this.dom.caption.offsetWidth + vwPx(1440, 30));
    height -= this.dom.caption.offsetHeight;

    gsap.set(this.dom.caption, { x: x, y: height });
  }

  render = () => {
    const bounds = this.dom.img.getBoundingClientRect();

    const scale = intersectMinMax(Math.abs(instances.scroll.state.last) + this.dom.el.offsetHeight + 120, this.dom.el, 0.33, 1, false);

    if (scale >= 1) instances.time.emitter.off('tick', this.render);

    gsap.set(this.dom.img, { scale: scale });
    gsap.set(this.dom.caption, {
      x: bounds.x - (this.dom.caption.offsetWidth + vwPx(1440, 30)),
      y: Math.abs(bounds.height - this.dom.caption.offsetHeight)
    });
  }

  init() {
    this.setCache();

    inView(this.dom.el, this.render);
  }

  destroy() {
    instances.time.emitter.off('tick', this.render);
  }
}

export default Image;
