import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

import { constants } from '../../store';

gsap.registerPlugin(CustomEase);

class HouseTypes {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;

    this.dom.list = this.dom.el.querySelector('.js-house-list');
    this.dom.items = this.dom.list.querySelectorAll('.js-house-item');
    this.dom.links = this.dom.list.querySelectorAll('.js-house-link');

    this.dom.imgs = this.dom.el.querySelector('.js-imgs');
    this.dom.imgMask = this.dom.el.querySelectorAll('.js-img-mask');
    this.dom.imgWraps = this.dom.el.querySelectorAll('.js-img-wrap');

    this.dom.heading = this.dom.el.querySelector('.js-heading');
    this.dom.overlay = this.dom.el.querySelector('.js-overlay');
    this.dom.slider = this.dom.el.querySelector('.js-house-slider');

    this.state = {
      zIndex: 0,
      hovering: 0
    };

    if (constants.isDevice) {
      [...this.dom.links].forEach((link) => delete link.dataset.transition);
    }
  }

  setCache() {
    this.items = [];

    [...this.dom.items].forEach((item, index) => {
      this.items.push({
        item,
        imgWrap: this.dom.imgWraps[index],
        mask: this.dom.imgMask[index],
        img: this.dom.imgWraps[index].querySelector('.js-img'),
        active: false
      });
    });
  }

  toggleSlider = () => {
    if (!this.state.hover) {
      this.state.hover = true;

      gsap.to(this.dom.imgs, { autoAlpha: 1, duration: 0.4, ease: 'soft-ease' });
    } else {
      this.state.hover = false;

      gsap.to(this.dom.imgs, { autoAlpha: 0, duration: 0.4, ease: 'soft-ease' });

      this.items.forEach((el) => {
        gsap.to(el.item, { autoAlpha: 1, duration: 0.4, ease: 'soft-ease', clearProps: 'opacity' });
        gsap.to(el.mask, { autoAlpha: 0, duration: 0.4, ease: 'soft-ease', clearProps: 'all' });
        gsap.to(el.imgWrap, { autoAlpha: 0, duration: 0.4, ease: 'soft-ease', clearProps: 'all' });
      });
    }
  }

  changeImage = (i) => {
    const el = this.items[i];
    this.state.hovering = i;

    this.items.forEach((item, index) => {
      if (index === i) {
        gsap.killTweensOf([item.mask, item.imgWrap]);
      } else {
        gsap.set(el.img, { scale: 1.4, y: 240, transformOrigin: 'center center' });
      }
    });

    this.state.zIndex += 1;
    el.mask.style.zIndex = this.state.zIndex;

    gsap.set(el.mask, { yPercent: 100, autoAlpha: 1 });
    gsap.set(el.imgWrap, { yPercent: -100, autoAlpha: 1 });

    gsap.to([el.mask, el.imgWrap], { yPercent: 0, duration: 0.95, ease: 'strong-in-out-ease' });
    gsap.to(el.img, { scale: 1, y: 0, duration: 0.95, ease: 'strong-in-out-ease' });
  }

  addListeners() {
    [...this.dom.items].forEach((item, index) => { item.addEventListener('mouseenter', () => this.changeImage(index)); });

    this.dom.list.addEventListener('mouseenter', this.toggleSlider);
    this.dom.list.addEventListener('mouseleave', this.toggleSlider);
  }

  removeListeners() {
    [...this.dom.items].forEach((item, index) => { item.removeEventListener('mouseenter', () => this.changeImage(index)); });

    this.dom.list.removeEventListener('mouseenter', this.toggleSlider);
    this.dom.list.removeEventListener('mouseleave', this.toggleSlider);
  }

  init() {
    if (constants.isDevice) return;

    let i = 0;

    [...this.dom.imgWraps].forEach((item) => {
      const interval = setInterval(() => {
        if (item.querySelector('.js-img')) {
          clearInterval(interval);

          i += 1;
        }
      });
    });

    const interval = setInterval(() => {
      if (this.dom.imgWraps.length === i) {
        clearInterval(interval);

        this.setCache();
        this.addListeners();
      }
    });
  }

  destroy() {
    if (constants.isDevice) return;

    this.removeListeners();
  }
}

export default HouseTypes;
