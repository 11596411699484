import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { SplitText } from 'gsap/SplitText';

import { constants, instances } from '../../store';

gsap.registerPlugin(SplitText, CustomEase);

CustomEase.create('page-transition-e', 'M0,0 C0.45,0 0.58,1 1,1');
CustomEase.create('page-transition-e2', 'M0,0 C0.24,0 0.27,1 1,1');
CustomEase.create('s-ease-long-out', 'M0,0 C0.22,1 0.36,1 1,1');
CustomEase.create('soft-ease', 'M0,0 C0,0 0.58,1 1,1');
CustomEase.create('strong-in-out-ease', 'M0,0 C0.55,0 0.44,1 1,1');
CustomEase.create('main-text-ease', 'M0,0 C0.22,1 0.36,1 1,1 ');
CustomEase.create('secondaire-text-ease', 'M0,0 C0.42,0 0.3,1 1,1');
CustomEase.create('image', 'M0,0 C0.37,0 0.2,1 1,1');

class Menu {
  constructor() {
    this.dom = {};

    this.dom.el = document.querySelector('.js-menu');
    this.dom.closeButton = this.dom.el.querySelector('.js-close');
    this.dom.background = this.dom.el.querySelector('.js-background');
    this.dom.imageMasks = this.dom.el.querySelectorAll('.js-mask');
    this.dom.list = this.dom.el.querySelector('.js-main-list');
    this.dom.items = this.dom.el.querySelectorAll('.js-main-item');
    this.dom.links = this.dom.list.querySelectorAll('.js-main-link');

    this.dom.nav = document.querySelector('.js-nav');
    this.dom.menuButton = this.dom.nav.querySelector('.js-button');

    this.dom.langSwitch = this.dom.el.querySelector('.js-lang-switch') || document.querySelector('.js-lang-switch-nav');
    this.dom.langIcon = this.dom.langSwitch.querySelector('.js-lang-icon');

    this.dom.langSwitchNav = document.querySelector('.js-lang-switch-nav');
    this.dom.langIconNav = this.dom.langSwitchNav.querySelector('.js-lang-icon');

    this.dom.page = document.querySelector('.js-page');
    this.dom.transition = document.querySelector('.js-transition');
    this.dom.registerCTA = document.querySelector('.js-register-cta');
    this.dom.btt = document.querySelector('.js-back-to-top');

    this.dom.header = this.dom.page.querySelector('.js-header');
    this.dom.fluidImages = this.dom.page.querySelectorAll('[data-image="fluid"]');

    this.state = {
      background: this.dom.background,
      closed: true,
      open: false,
      done: true,
      el: this.dom.el,
      langSwitch: false,
      langSwitchNav: false
    };

    this.height = 0;
  }

  setCache = () => {
    this.images = [];

    [...this.dom.imageMasks].forEach((mask) => {
      this.images.push({
        mask,
        wrap: mask.querySelector('.menu__img-wrap'),
        img: mask.querySelector('img'),
      });
    });
  }

  createAnimation() {
    this.tlOpen = gsap.timeline({ paused: true });

    // Part 1
    this.tlOpen
      .addLabel('part_1')
      .set(this.dom.transition, { yPercent: 100, autoAlpha: 1 })
      .set(this.dom.el, { y: 450 })

      .to(this.dom.transition, { yPercent: 0, duration: 0.6, ease: 'page-transition-e' }, 'part_1')
      .to([this.dom.page, this.dom.nav], { y: -450, duration: 0.6, scale: 0.9, transformOrigin: `${window.innerWidth / 2}px ${this.height}px`, ease: 'page-transition-e', clearProps: 'all' }, 'part_1');

    if (constants.registerCTA) {
      this.tlOpen.to(this.dom.registerCTA, { autoAlpha: 0 });
    }

    if (this.dom.fluidImages.length > 0) {
      [...this.dom.fluidImages].forEach((wrap) => {
        this.tlOpen.to(wrap, { scale: 1 / 0.9, duration: 0.6, transformOrigin: `${window.innerWidth / 2}px ${this.height}px`, ease: 'page-transition-e', clearProps: 'scale' }, 'part_1');
      });
    }

    // Part 2
    this.tlOpen
      .addLabel('part_2')
      .set(this.dom.el, { autoAlpha: 1 });

    [...this.dom.links].forEach((link) => {
      const headingChars = new SplitText(link, { type: 'chars', charsClass: 'js-char' }).chars;

      this.tlOpen.set(headingChars, { y: headingChars[0].offsetHeight });
    });

    this.tlOpen
      .set(this.dom.list, { autoAlpha: 1 })
      .to(this.dom.transition, { yPercent: -100, duration: 0.9, ease: 'page-transition-e2', clearProps: 'all', onComplete: () => {
        this.state.animationCompleted = true;
      } }, 'part_2')
      .to(this.dom.el, { y: 0, duration: 0.9, ease: 'ease-short-in-long-out', clearProps: 'transform' }, 'part_2-=0.2');

    [...this.dom.links].forEach((link) => {
      const headingChars = link.querySelectorAll('.js-char');

      this.tlOpen.to(headingChars, { y: 0, duration: 1.12, stagger: 0.015, ease: 'main-text-ease' }, 'part_2+=0.53');
    });
  }

  openMenu = () => {
    if (!instances.scroll) return;

    setTimeout(() => {
      this.dom.list.addEventListener('mouseenter', this.toggleHover);
      this.dom.list.addEventListener('mouseleave', this.toggleHover);
    }, 1730);

    if (constants.backToTop) {
      this.tlOpen.to(this.dom.btt, { autoAlpha: 0 }, 'part_1');
    }

    if (!this.state.done) {
      if (!constants.isDevice) instances.scroll.enable();

      this.state.done = true;
      this.state.open = false;

      this.tlOpen.reverse();
    } else {
      if (!constants.isDevice) instances.scroll.disable();

      this.state.done = false;
      this.state.open = true;

      if (this.state.animationCompleted) {
        this.state.animationCompleted = false;

        this.tlOpen.play(0);
      } else {
        const { top } = this.dom.page.getBoundingClientRect();
        this.height = (top >= 0) ? window.innerHeight / 2 : Math.abs(top);

        this.tlOpen.play();
      }
    }
  }

  closeMenu = () => {
    if (!constants.isDevice) instances.scroll.enable();

    this.dom.list.removeEventListener('mouseenter', this.toggleHover);
    this.dom.list.removeEventListener('mouseleave', this.toggleHover);

    this.state.done = true;
    this.state.open = false;

    if (this.state.animationCompleted) {
      this.tlClose = gsap.timeline({ paused: true });

      this.tlClose
        .addLabel('start')
        .set(this.dom.transition, { autoAlpha: 1, yPercent: 100 })
        .to(this.dom.transition, { yPercent: 0, duration: 0.6, ease: 'page-transition-e' }, 'start')
        .to(this.dom.el, { y: -450, duration: 0.6, ease: 'page-transition-e', clearProps: 'all' }, 'start')

        .set([this.dom.page, this.dom.nav], { y: 450 })

        .to(this.dom.transition, { yPercent: -100, duration: 0.7, ease: 'page-transition-e2', clearProps: 'all' }, 'start+=0.8')
        .to([this.dom.page, this.dom.nav], { y: 0, duration: 0.7, ease: 'page-transition-e2', clearProps: 'transform' }, 'start+=0.8');

      if (constants.registerCTA && this.dom.registerCTA) {
        this.tlClose.to(this.dom.registerCTA, { autoAlpha: 1 });
      }

      if (constants.backToTop) {
        this.tlClose.to(this.dom.btt, { autoAlpha: 1 });
      }

      if (this.dom.fluidImages.length > 0) {
        [...this.dom.fluidImages].forEach((wrap) => {
          wrap.style.overflow = null;
          this.dom.header.style.overflow = null;

          this.tlClose
            .set(wrap, { y: 450, scale: 1.3 }, 'start+=0.8')
            .to(wrap, { y: 0, scale: 1, duration: 0.9, transformOrigin: `${window.innerWidth / 2}px ${this.height}px`, ease: 'page-transition-e2', clearProps: 'scale' }, 'start+=0.8');
        });
      }

      this.tlClose.play();
    } else {
      this.tlOpen.reverse();
    }
  }

  toggleHover = (e) => {
    const { type } = e;

    if (type === 'mouseenter') {
      gsap.killTweensOf(this.dom.background);
      gsap.to(this.dom.background, { autoAlpha: 0.6, duration: 0.4 });
    } else {
      gsap.to(this.dom.background, { autoAlpha: 0, duration: 0.4, onComplete: () => {
        [...this.images].forEach((el) => {
          el.mask.style.zIndex = null;
          gsap.set(el.wrap, { autoAlpha: 0 });
        });
      } });
    }
  }

  changeImage = (index) => {
    const current = this.images[index];

    [...this.images].forEach((el) => {
      el.mask.style.zIndex = null;
    });

    gsap.killTweensOf(current.img);

    current.mask.style.zIndex = 2;

    gsap.set(current.wrap, { autoAlpha: 1 });
    gsap.set(current.img, { scale: 1.2 });
    gsap.to(current.img, { scale: 1, duration: 1.4, ease: 'image' });
    gsap.to(current.img, { scale: 1.5, duration: 300, delay: 1.4, yoyo: true, repeat: -1 });
  }

  toggleLangSwitch = () => {
    if (!this.state.langSwitch) {
      this.state.langSwitch = true;
      this.dom.langSwitch.style.overflow = 'visible';
      this.dom.langIcon.style.transform = 'rotate(180deg)';
    } else {
      this.state.langSwitch = false;
      this.dom.langSwitch.style.overflow = null;
      this.dom.langIcon.style.transform = null;
    }
  }

  toggleLangSwitchNav = () => {
    if (!this.state.langSwitchNav) {
      this.state.langSwitchNav = true;
      this.dom.langSwitchNav.style.overflow = 'visible';
      this.dom.langIcon.style.transform = 'rotate(180deg)';
    } else {
      this.state.langSwitchNav = false;
      this.dom.langSwitchNav.style.overflow = null;
      this.dom.langIcon.style.transform = null;
    }
  }

  addListeners() {
    this.dom.menuButton.addEventListener('click', this.openMenu);
    this.dom.closeButton.addEventListener('click', this.closeMenu);

    [...this.dom.items].forEach((item, index) => item.addEventListener('mouseenter', () => { this.changeImage(index); }));

    this.dom.langSwitch.addEventListener('click', this.toggleLangSwitch);
    this.dom.langSwitchNav.addEventListener('click', this.toggleLangSwitchNav);
  }

  removeListeners() {
    this.dom.menuButton.removeEventListener('click', this.openMenu);
    this.dom.closeButton.removeEventListener('click', this.closeMenu);

    this.dom.list.removeEventListener('mouseenter', this.toggleHover);
    this.dom.list.removeEventListener('mouseleave', this.toggleHover);

    [...this.dom.items].forEach((item, index) => item.removeEventListener('mouseenter', () => { this.changeImage(index); }));

    this.dom.langSwitch.removeEventListener('click', this.toggleLangSwitch);
    this.dom.langSwitchNav.removeEventListener('click', this.toggleLangSwitchNav);
  }

  init() {
    this.addListeners();
    this.setCache();
    this.createAnimation();
  }

  destroy() {
    this.removeListeners();
  }
}

export default Menu;
