import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

import { constants, instances } from '../../store';
import { inView } from '../../utils/inView';

gsap.registerPlugin(CustomEase);

CustomEase.create('s-ease-long-out', 'M0,0 C0.22,1 0.36,1 1,1');
CustomEase.create('button-vlak-scale', 'M0,0 C0.5,0 0.21,1 1,1');

class Nav {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-nav');

    this.dom.lists = this.dom.el.querySelectorAll('.js-list');
    this.dom.leftItems = this.dom.el.querySelectorAll('.js-link-left');
    this.dom.rightItems = this.dom.el.querySelectorAll('.js-link-right');

    this.dom.button = this.dom.el.querySelector('.js-button');
    this.dom.orangeCircle = this.dom.button.querySelector('.js-orange-circle');
    this.dom.burgerIcon = this.dom.button.querySelector('.js-burger-icon');
    this.dom.outerCircle = this.dom.button.querySelector('.js-outer-circle > ellipse');
    this.dom.paths = this.dom.button.querySelectorAll('.js-path');
    this.dom.pathsCopy = this.dom.button.querySelectorAll('.js-path-copy');

    this.dom.logo = this.dom.el.querySelector('.js-logo');

    this.dom.header = document.querySelector('.js-header');

    this.state = {
      top: true,
      animating: false,
      stroke: undefined,
      theme: (this.dom.header) ? this.dom.header.dataset.nav : null
    };
  }

  toggleNav = () => {
    if (this.state.top) {
      this.state.top = false;

      // Nav
      gsap.to(this.dom.lists, { autoAlpha: 0, ease: 's-ease-long-out', duration: 0.77 });
      gsap.to(this.dom.leftItems, { x: 10, ease: 's-ease-long-out', stagger: -0.05, duration: 0.77 });
      gsap.to(this.dom.rightItems, { x: -10, ease: 's-ease-long-out', stagger: -0.05, duration: 0.77 });

      // Logo
      gsap.to(this.dom.logo, { scale: 1.1, ease: 's-ease-long-out', duration: 0.77 });

      // Menu button
      gsap.to(this.dom.orangeCircle, { scale: 1, ease: 's-ease-long-out', duration: 0.77 });
      gsap.to(this.dom.outerCircle, { scale: 0.9, autoAlpha: 0, transformOrigin: 'center center', ease: 's-ease-long-out', duration: 0.77 });
      gsap.to(this.dom.burgerIcon, { stroke: (document.body.classList.contains('is-dark')) ? '#111' : '#fff', ease: 's-ease-long-out', duration: 0.77 });
    } else {
      this.state.top = true;

      // Nav
      gsap.to(this.dom.lists, { autoAlpha: 1, ease: 's-ease-long-out', duration: 0.77 });
      gsap.to(this.dom.leftItems, { x: 0, ease: 's-ease-long-out', stagger: 0.05, duration: 0.77 });
      gsap.to(this.dom.rightItems, { x: 0, ease: 's-ease-long-out', stagger: 0.05, duration: 0.77 });

      // Logo
      gsap.to(this.dom.logo, { scale: 1, ease: 's-ease-long-out', duration: 0.77 });

      // Menu button
      gsap.to(this.dom.orangeCircle, { scale: 0, ease: 's-ease-long-out', duration: 0.77 });
      gsap.to(this.dom.outerCircle, { scale: 1, autoAlpha: 1, ease: 's-ease-long-out', duration: 0.77 });
      gsap.to(this.dom.burgerIcon, { stroke: this.state.stroke, ease: 's-ease-long-out', duration: 0.77 });
    }
  }

  hoverIn = () => {
    if (!this.state.animating) {
      this.state.animating = true;

      gsap.set(this.dom.pathsCopy, { x: -this.dom.burgerIcon.getBoundingClientRect().width - 15, autoAlpha: 1 });

      gsap.to(this.dom.pathsCopy, { x: 0, stagger: 0.12, duration: 0.81, ease: 'button-vlak-scale', clearProps: 'all' });
      gsap.to(this.dom.paths, { x: this.dom.burgerIcon.getBoundingClientRect().width + 15, stagger: 0.12, duration: 0.81, ease: 'button-vlak-scale', clearProps: 'all', onComplete: () => {
        this.state.animating = false;
      } });
    }
  }

  render = () => {
    if (instances.scroll.state.current < 0 && this.state.top) {
      this.toggleNav();
    } else if (instances.scroll.state.current === 0 && !this.state.top) {
      this.toggleNav();
    }
  }

  addListener() {
    if (!constants.isDevice) {
      window.addEventListener('wheel', (e) => {
        if (e.deltaY > 0 && this.state.top) {
          this.toggleNav();
        } else if (e.deltaY < 0 && instances.scroll.state.current === 0 && !this.state.top) {
          this.toggleNav();
        }
      });

      this.dom.button.addEventListener('mouseenter', this.hoverIn);
    }

    if (constants.isDevice && window.innerWidth > 768) {
      inView(this.dom.header, this.render);
    }
  }

  removeListener() {
    window.removeEventListener('wheel', () => {});

    this.dom.button.removeEventListener('mouseenter', this.hoverIn);
  }

  init() {
    if (window.innerWidth < 768) return;

    const interval = setInterval(() => {
      if (constants.pageEntranceFinished && instances.scroll) {
        clearInterval(interval);
        this.addListener();

        this.state.stroke = (document.body.classList.contains('is-dark') || this.dom.el.classList.contains('is-dark')) ? '#fff' : '#111';
      }
    });
  }

  destroy() {
    if (window.innerWidth < 768) return;

    this.state.top = true;

    this.removeListener();
    gsap.set([this.dom.lists, this.dom.leftItems, this.dom.rightItems, this.dom.logo], { clearProps: 'all' });
    gsap.set(this.dom.burgerIcon, { clearProps: 'stroke' });

    instances.time.emitter.off('tick', this.render);
  }
}

export default Nav;
