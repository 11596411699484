import { constants } from '../../store';
import { inView } from '../../utils/inView';
import { lerp, vwPx } from '../../utils/math';
import { instances } from '../../store';

class StaticCarousel {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;
    this.dom.slider = this.dom.el.querySelector('.js-slider');
    this.dom.wrap = this.dom.el.querySelector('.js-wrap');
    this.dom.items = this.dom.el.querySelectorAll('.js-item');
    this.dom.navigation = {
      prev: this.dom.el.querySelector('.js-prev'),
      next: this.dom.el.querySelector('.js-next'),
    };

    this.state = {
      current: 0,
      last: 0,
      isDragging: false,
      onX: 0,
      offX: 0,
      ease: 0.1,
      min: 0,
      max: 0,
      itemWidth: 0,
      slidesTotal: this.dom.items.length,
      slideX: 0
    };
  }

  setCache() {
    const itemWidth = this.dom.items[0].offsetWidth;
    const padding = this.dom.wrap.currentStyle || window.getComputedStyle(this.dom.wrap);

    if (!constants.isDevice) {
      this.state.containerWidth = (this.state.slidesTotal * itemWidth) + ((this.state.slidesTotal - 1) * vwPx(1440, 30));
    } else {
      this.state.containerWidth = (this.state.slidesTotal * itemWidth) + ((this.state.slidesTotal - 1) * vwPx(360, 15));
    }

    this.state.max = -(this.state.containerWidth - window.innerWidth + (parseInt(padding.paddingLeft, 10) * 2));

    [...this.dom.items].forEach((el) => {
      el.style.transform = 'translateX(180px)';
    });
  }

  render = () => {
    this.state.last = lerp(this.state.last, this.state.current, this.state.ease);
    this.dom.wrap.style.transform = `translate3d(${this.state.last}px, 0, 0)`;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const percentage = (100 * Math.abs(this.dom.items[0].getBoundingClientRect().bottom - window.innerHeight - this.dom.items[0].offsetHeight)) / this.dom.items[0].offsetHeight;

          if (percentage >= 100) return;
          this.state.slideX = -120 + ((120 / 100) * percentage);
        }
      });
    });

    observer.observe(this.dom.items[0]);

    [...this.dom.items].forEach((el) => {
      el.style.transform = `translateX(${this.state.slideX}px)`;
    });
  }

  handleMouseDown = (e) => {
    this.state.isDragging = true;
    this.state.onX = e.pageX || e.touches[0].clientX;
  }

  handleMouseUp = () => {
    this.state.isDragging = false;
    this.state.offX = this.state.current;
  }

  handleMouseMove = (e) => {
    if (!this.state.isDragging) return;

    const dragX = e.clientX || e.touches[0].clientX;
    this.state.current = this.state.offX + ((dragX - this.state.onX) * 2);

    this.clamp();
  }

  clamp() {
    this.state.current = Math.max(Math.min(this.state.current, this.state.min), this.state.max);
  }

  next = () => {
    this.state.current += -this.dom.items[0].offsetWidth;
    this.clamp();
  }

  prev = () => {
    this.state.current += this.dom.items[0].offsetWidth;
    this.clamp();
  }

  addListeners() {
    if (!constants.isDevice) {
      this.dom.navigation.prev.addEventListener('click', this.prev);
      this.dom.navigation.next.addEventListener('click', this.next);

      this.dom.slider.addEventListener('mousedown', this.handleMouseDown);
      this.dom.slider.addEventListener('mouseup', this.handleMouseUp);
      this.dom.slider.addEventListener('mousemove', this.handleMouseMove);
      this.dom.slider.addEventListener('mouseleave', this.handleMouseUp);
    } else {
      this.dom.slider.addEventListener('touchstart', this.handleMouseDown);
      this.dom.slider.addEventListener('touchend', this.handleMouseUp);
      this.dom.slider.addEventListener('touchmove', this.handleMouseMove);
    }
  }

  removeListeners() {
    if (!constants.isDevice) {
      this.dom.navigation.prev.removeEventListener('click', this.prev);
      this.dom.navigation.next.removeEventListener('click', this.next);

      this.dom.slider.removeEventListener('mousedown', this.handleMouseDown);
      this.dom.slider.removeEventListener('mouseup', this.handleMouseUp);
      this.dom.slider.removeEventListener('mousemove', this.handleMouseMove);
      this.dom.slider.removeEventListener('mouseleave', this.handleMouseUp);
    } else {
      this.dom.slider.removeEventListener('touchstart', this.handleMouseDown);
      this.dom.slider.removeEventListener('touchend', this.handleMouseUp);
      this.dom.slider.removeEventListener('touchmove', this.handleMouseMove);
    }
  }

  init() {
    if (this.dom.items.length <= 3 && !constants.isDevice) return;

    this.setCache();

    inView(this.dom.el, this.render);

    this.addListeners();
  }

  destroy() {
    if (this.dom.items.length <= 3 && !constants.isDevice) return;

    instances.time.emitter.off('tick', this.render);

    this.removeListeners();
  }
}

export default StaticCarousel;
