import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

gsap.registerPlugin(CustomEase);

class ScrollBasedAnims {
  constructor() {
    this.dom = {};
    this.dom.els = document.querySelectorAll('[data-animation-in]');
    this.dom.app = document.querySelector('.js-app');
  }

  setCache() {
    this.els = [];

    [...this.dom.els].forEach((el) => {
      const bounds = el.getBoundingClientRect();
      const { animationIn } = el.dataset;

      const data = {
        el,
        bounds,
        animationIn,
        tl: undefined,
        animated: false
      };

      this.els.push(data);
    });
  }

  init() {
    if (this.dom.els.length > 0) {
      this.setCache();
    }
  }
}

export default ScrollBasedAnims;
