import Highway from '@dogstudio/highway';

import Button from '../components/button/button';
import Cursor from '../components/cursor/cursor';
import CTA from '../components/cta/cta';
import DoubleImage from '../components/image/double';
import Faq from '../components/faq/faq';
import Grid from '../components/image/grid';
import Header from '../components/header/header';
import HouseTypes from '../components/house_types/house_types';
import Numbers from '../components/numbers/numbers';
import Image from '../components/image/image';
import Panorama from '../components/image/panorama';
import InteractiveCarousel from '../components/carousel/_interactive';
import Features from '../components/features/features';
// import Slider from '../components/slider/slider';
import StaticCarousel from '../components/carousel/_static';
import Carousel from '../components/text_image/_carousel';
import Subscribe from '../components/subscribe/subscribe';
import { instances } from '../store';
import EnergyCalculator from '../components/energy_calculator/energy_calculator';
import OverlayGrid from '../components/overlay_grid/overlay_grid';

let header;
let headerEl;

let cursor;
let cursorEl;

let buttonEls;
const buttons = [];

let ctaEls;
const ctas = [];

let doubleImagesEls;
const doubleImages = [];

let EnergyCalculatorEls;
const EnergyCalculators = [];

let faqEls;
const faqs = [];

let gridEls;
const grids = [];

let numberEls;
const numbers = [];

let imageEls;
const images = [];

let panoramaEls;
const panoramas = [];

let interactiveCarouselEls;
const interactiveCarousels = [];

let featuresEls;
const features = [];

let textImageCarouselEls;
const textImageCarousels = [];

let houseTypesEls;
const houseTypes = [];

// let sliderEls;
// const sliders = [];

let staticCarouselEls;
const staticCarousels = [];

let subscribeEls;
const subscribes = [];

let OverlayGridEls;
const OverlayGrids = [];

class PageRenderer extends Highway.Renderer {
  onEnter() {}

  onEnterCompleted() {
    headerEl = document.querySelector('.js-header');
    if (headerEl) {
      header = new Header();
      header.init();
    }

    cursorEl = document.querySelector('[data-cursor]');
    if (cursorEl) {
      cursor = new Cursor();
      cursor.init();
      instances.cursor = cursor;
    }

    buttonEls = document.querySelectorAll('.js-film-button');
    [...buttonEls].forEach((el) => {
      const button = new Button(el);
      button.init();

      buttons.push(button);
    });

    ctaEls = document.querySelectorAll('.js-cta');
    [...ctaEls].forEach((el) => {
      const cta = new CTA(el);
      cta.init();

      ctas.push(cta);
    });

    doubleImagesEls = document.querySelectorAll('.js-double');
    [...doubleImagesEls].forEach((el) => {
      const double = new DoubleImage(el);
      double.init();

      doubleImages.push(double);
    });

    EnergyCalculatorEls = document.querySelectorAll('.js-energy-calculator');
    [...EnergyCalculatorEls].forEach((el) => {
      const calculator = new EnergyCalculator(el);
      calculator.init();

      EnergyCalculators.push(calculator);
    });

    faqEls = document.querySelectorAll('.js-faq');

    [...faqEls].forEach((el) => {
      const faq = new Faq(el);

      faq.init();

      faqs.push(faq);
    });

    gridEls = document.querySelectorAll('.js-grid');
    [...gridEls].forEach((el) => {
      const grid = new Grid(el);
      grid.init();

      grids.push(grid);
    });

    imageEls = document.querySelectorAll('.js-image-scale');
    [...imageEls].forEach((el) => {
      const image = new Image(el);
      image.init();

      images.push(image);
    });

    panoramaEls = document.querySelectorAll('.js-panorama');
    [...panoramaEls].forEach((el) => {
      const panorama = new Panorama(el);
      panorama.init();

      panoramas.push(panorama);
    });

    numberEls = document.querySelectorAll('.js-numbers');
    [...numberEls].forEach((el) => {
      const number = new Numbers(el);
      number.init();

      numbers.push(number);
    });

    interactiveCarouselEls = document.querySelectorAll('.js-interactive-carousel');
    [...interactiveCarouselEls].forEach((el) => {
      const carousel = new InteractiveCarousel(el);
      carousel.init();

      interactiveCarousels.push(carousel);
    });

    featuresEls = document.querySelectorAll('.js-features');
    [...featuresEls].forEach((el) => {
      const feature = new Features(el);
      feature.init();

      features.push(feature);
    });

    textImageCarouselEls = document.querySelectorAll('.js-text-image');
    [...textImageCarouselEls].forEach((el) => {
      const carousel = new Carousel(el);
      carousel.init();

      textImageCarousels.push(carousel);
    });

    houseTypesEls = document.querySelectorAll('.js-house-types');
    [...houseTypesEls].forEach((el) => {
      instances.houseType = new HouseTypes(el);
      instances.houseType.init();

      houseTypes.push(instances.houseType);
    });

    // sliderEls = document.querySelectorAll('.js-single-slider');
    // [...sliderEls].forEach((el) => {
    //   const slider = new Slider(el);
    //   slider.init();

    //   sliders.push(slider);
    // });

    staticCarouselEls = document.querySelectorAll('.js-static-carousel');
    [...staticCarouselEls].forEach((el) => {
      const carousel = new StaticCarousel(el);
      carousel.init();

      staticCarousels.push(carousel);
    });

    subscribeEls = document.querySelectorAll('.js-subscribe');
    [...subscribeEls].forEach((el) => {
      const subscribe = new Subscribe(el);
      subscribe.init();

      subscribes.push(subscribe);
    });

    OverlayGridEls = document.querySelectorAll('.js-overlay-grid');
    [...OverlayGridEls].forEach((el) => {
      const grid = new OverlayGrid(el);
      grid.init();

      OverlayGrids.push(grid);
    });
  }

  onLeave() {
    if (headerEl) {
      header.destroy();
      header = undefined;
    }

    if (cursorEl) {
      cursor.destroy();
      cursor = undefined;
    }

    ctas.forEach((cta) => {
      cta.destroy();
      cta = undefined;
    });
    ctas.length = 0;

    features.forEach((feature) => {
      feature.destroy();
      feature = undefined;
    });
    features.length = 0;

    // faqs.forEach((faq) => {
    //   faq.destroy();
    //   faq = undefined;
    // });
    // faqs.length = 0;

    interactiveCarousels.forEach((carousel) => {
      carousel.destroy();
      carousel = undefined;
    });
    interactiveCarousels.length = 0;

    doubleImages.forEach((double) => {
      double.destroy();
      double = undefined;
    });
    doubleImages.length = 0;

    houseTypes.forEach((houseType) => {
      houseType.destroy();
      houseType = undefined;
    });
    houseTypes.length = 0;

    images.forEach((image) => {
      image.destroy();
      image = undefined;
    });
    images.length = 0;

    numbers.forEach((number) => {
      number.destroy();
      number = undefined;
    });
    numbers.length = 0;

    staticCarousels.forEach((carousel) => {
      carousel.destroy();
      carousel = undefined;
    });
    staticCarousels.length = 0;

    subscribes.forEach((subscribe) => {
      subscribe.destroy();
      subscribe = undefined;
    });
    subscribes.length = 0;

    OverlayGrids.forEach((grid) => {
      grid.destroy();
      grid = undefined;
    });
    OverlayGrids.length = 0;
  }

  onLeaveCompleted() {}
}

export default PageRenderer;
