import { gsap } from 'gsap';

class Faq {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;
    this.dom.items = this.dom.el.querySelectorAll('.js-item');
  }

  setCache() {
    this.questions = [];

    [...this.dom.items].forEach((el) => {
      this.questions.push({
        el,
        anwser: el.querySelector('.js-answer'),
        icon: el.querySelector('.js-vertical'),
        active: false
      });
    });
  }

  toggle(i) {
    const current = this.questions[i];

    current.active = !current.active;

    gsap.to(current.anwser, { height: current.active ? 'auto' : 0, marginTop: current.active ? 20 : 0,
      onComplete: () => {
        window.dispatchEvent(new Event('resize'));
      }
    });

    gsap.to(current.icon, { rotation: current.active ? 90 : 0, autoAlpha: current.active ? 0 : 1, transformOrigin: '50% 50%', });
  }

  addEventListeners() {
    [...this.dom.items].forEach((el, i) => { el.addEventListener('click', () => { this.toggle(i); }); });
  }

  removeEventListener() {
    [...this.dom.items].forEach((el, i) => { el.removeEventListener('click', () => { this.toggle(i); }); });
  }

  init() {
    this.setCache();

    this.addEventListeners();
  }

  destroy() {
    this.removeEventListener();
  }
}

export default Faq;
