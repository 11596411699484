export const constants = {
  isDevice: undefined,
  namespace: undefined,
  pageEntranceFinished: false,
  showPreloader: false,
  registerCTA: true,
  backToTop: false,
};

export const instances = {
  scroll: undefined,
  cursor: undefined,
  reigsterCTA: undefined,
};
