import Highway from '@dogstudio/highway';
import { onLeave, onEnter, onEnterCompleted } from '../renderers/global';

// renderers
import PageRenderer from '../renderers/page';

// transitions
import defaultTransition from '../transitions/default';
import houseTransition from '../transitions/house';

// setup highway core
export const H = new Highway.Core({
  renderers: {
    page: PageRenderer
  },
  transitions: {
    default: defaultTransition,

    contextual: {
      default: defaultTransition,
      house: houseTransition
    }
  }
});

// global highway events
H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {
  onLeave({ from, trigger, location });
});

H.on('NAVIGATE_IN', ({ to, trigger, location }) => {
  onEnter({ to, trigger, location });
});

H.on('NAVIGATE_END', ({
  from, to, trigger, location
}) => {
  if (typeof gtag !== 'undefined') {
    // eslint-disable-next-line
    gtag('config', 'G-6DZ3GKBJJW', {
      page_path: location.pathname,
      page_title: to.page.title,
      page_location: location.href
    });
  }

  onEnterCompleted({
    from, to, trigger, location
  });
});
