import Highway from '@dogstudio/highway';
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { CustomEase } from 'gsap/CustomEase';

import { vwPx, pxVw } from '../utils/math';

import { instances, constants } from '../store';
import { criticalImagesLoaded } from '../utils/critical-images-loaded';

gsap.registerPlugin(SplitText, CustomEase);

CustomEase.create('ease-short-in-long-out', 'M0,0 C0.35,0 0.42,1 1,1');
CustomEase.create('secondaire-text-ease', 'M0,0 C0.42,0 0.3,1 1,1  ');
CustomEase.create('secondaire-text-ease-2', 'M0,0 C0.14,0.5 0.34,1 1,1 ');
CustomEase.create('strong-in-out-ease', 'M0,0 C0.55,0 0.44,1 1,1 ');
CustomEase.create('ease-smooth-in-out-long', 'M0,0 C0.38,0 0.15,1 1,1 ');
CustomEase.create('ease-scale-down', 'M0,0 C0.42,0 0.3,1 1,1 ');

const app = document.querySelector('.js-app');

const nav = document.querySelector('.js-nav');
const navButton = nav.querySelector('.js-button');

class HouseTransition extends Highway.Transition {
  out({ trigger, from, done }) {
    instances.scroll.destroy();

    const el = instances.houseType;

    // Clone img
    const imgSrc = trigger.dataset.img;

    this.imgWrap = document.createElement('div');
    this.imgWrap.classList.add('img-clone', 'js-img-clone');
    this.imgWrap.style.cssText = `
      width: ${pxVw(1440, el.dom.slider.getBoundingClientRect().width)}vw;
      height: ${pxVw(1440, el.dom.slider.getBoundingClientRect().height)}vw;
      overflow: hidden;
      position: relative;
      transform: translate(${el.dom.slider.getBoundingClientRect().x}px, ${el.dom.slider.getBoundingClientRect().y}px);
    `;

    this.overlay = document.createElement('div');
    this.overlay.classList.add('img-overlay', 'js-overlay');
    this.overlay.style.cssText = `
      position: absolute;
      width: 100%;
      height: 100%;
      background: var(--color-black);
      z-index: 1;
      opacity: 0.5;
    `;

    this.imgWrap.appendChild(this.overlay);

    this.img = document.createElement('img');
    this.img.classList.add('img', 'js-img');
    this.img.src = imgSrc;
    this.img.style.cssText = `
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
    `;

    this.imgWrap.appendChild(this.img);

    app.appendChild(this.imgWrap);

    // Remove all components
    const pageChildren = from.querySelectorAll('[data-scroll]');
    const links = el.dom.links;
    const heading = el.dom.heading;
    const logo = document.querySelector('.js-logo');

    const tl = gsap.timeline({ paused: true, onComplete: () => {
      done();
    } });

    tl
      .addLabel('start')
      .to(heading, { y: -links[0].offsetHeight, autoAlpha: 0, duration: 0.8, ease: 'ease-smooth-in-out-long' }, 'start')
      .to(links, { y: links[0].offsetHeight, stagger: 0.05, duration: 0.8, ease: 'ease-smooth-in-out-long' }, 'start')
      .to(logo, { scale: 1, duration: 0.8, ease: 'ease-smooth-in-out-long' }, 'start')
      .to(navButton, { scale: 0, duration: 0.8, ease: 'ease-smooth-in-out-long' }, 'start');

    [...pageChildren].forEach((child) => {
      if (child !== el.dom.el) {
        tl.to(child, { autoAlpha: 0, duration: 0.8, ease: 'ease-smooth-in-out-long' }, 'start');
      }
    });

    tl
      .to(this.imgWrap, { y: (window.innerHeight - this.imgWrap.offsetHeight) / 2, scale: 0.77, transformOrigin: 'center center', duration: 0.87, ease: 'ease-scale-down' }, 'start')
      .to(this.img, { scale: 1 / 0.77, transformOrigin: 'center center', duration: 0.87, ease: 'ease-scale-down' }, 'start')

      .to(this.overlay, { autoAlpha: 0, duration: 0.34, ease: 'ease-smooth-in-out-long', onComplete: () => this.overlay.remove() }, 'start')

      .to(this.imgWrap, { scale: 1, y: 0, x: 0, width: window.innerWidth, height: `${vwPx(1440, 1139)}px`, duration: 1.05, ease: 'ease-scale-down' }, 'start+=0.87')
      .to(this.img, { scale: 1, duration: 1.05, ease: 'ease-scale-down' }, 'start+=0.87');

    const interval = setInterval(() => {
      if (this.img.complete) {
        clearInterval(interval);

        el.dom.slider.style.background = 'unset';
        el.dom.imgs.remove();
        tl.play();
      }
    });
  }

  in({ to, from, done }) {
    this.imgWrap.style.zIndex = 1;
    from.remove();

    const tl = gsap.timeline({
      paused: true,
      onComplete: () => {
        document.body.classList.remove('is-transitioning');
        constants.pageEntranceFinished = true;
      }
    });

    // nav
    const dom = {};

    // nav
    dom.nav = document.body.querySelector('.js-nav');
    dom.navItems = dom.nav.querySelectorAll('.js-item');
    dom.navLinks = dom.nav.querySelectorAll('.js-link');
    dom.navList = dom.nav.querySelectorAll('.js-list');
    dom.button = dom.nav.querySelector('.js-button');
    dom.buttonOuterCircle = dom.button.querySelector('.js-outer-circle');
    dom.buttonOuterSvg = dom.button.querySelector('.js-outer-circle > ellipse');
    dom.buttonYellowCircle = dom.button.querySelector('.js-yellow-circle');
    dom.buttonBurger = dom.button.querySelectorAll('.js-path');

    // header
    dom.header = to.querySelector('.js-header');
    dom.overlay = dom.header.querySelector('.js-overlay');
    dom.chapeau = dom.header.querySelector('.js-chapeau');
    dom.chapeauLine = new SplitText(dom.chapeau, { type: 'lines' }).lines;
    dom.heading = dom.header.querySelector('.js-heading');
    dom.headingLines = new SplitText(dom.heading, { type: 'lines', linesClass: 'header__heading-wrap js-heading-wrap' }).lines;

    tl
      .addLabel('start')

      // nav
      .set(dom.navLinks, { y: dom.navLinks[0].offsetHeight, x: 0 })
      .set(dom.buttonOuterSvg, { strokeDashoffset: dom.buttonOuterSvg.getTotalLength(), strokeDasharray: dom.buttonOuterSvg.getTotalLength() })
      .set(dom.buttonBurger, { strokeDashoffset: dom.buttonBurger[0].getTotalLength(), strokeDasharray: dom.buttonBurger[0].getTotalLength() })
      .set([dom.navItems, dom.button, dom.navList, dom.buttonOuterCircle], { autoAlpha: 1 })
      .set(dom.button, { scale: 1 })
      .set(dom.buttonYellowCircle, { scale: 0 })

    // Header
      .set(dom.chapeauLine, { y: dom.chapeau.offsetHeight });

    [...dom.headingLines].forEach((line) => {
      const headingChars = new SplitText(line, { type: 'chars', charsClass: 'js-char' }).chars;

      tl
        .set(headingChars, { y: headingChars[0].offsetHeight });
    });

    tl
      .set([dom.chapeau, dom.heading], { autoAlpha: 1 })

    // nav
      .to(dom.navLinks, { y: 0, duration: 0.85, ease: 'secondaire-text-ease' }, 'start')
      .to(dom.buttonOuterSvg, { strokeDashoffset: 0, duration: 0.58, ease: 'secondaire-text-ease' }, 'start')
      .to(dom.buttonBurger, { strokeDashoffset: 0, duration: 0.58, stagger: 0.05, ease: 'secondaire-text-ease' }, 'start')

    // Header
      .to(dom.chapeauLine, { y: 0, duration: 0.85, ease: 'secondaire-text-ease-2' }, 'start+=0.58')
      .to(dom.overlay, { autoAlpha: 0.2, duration: 0.87, ease: 'in-out-smooth' }, 'start');

    [...dom.headingLines].forEach((line) => {
      const headingChars = line.querySelectorAll('.js-char');

      tl.to(headingChars, { y: 0, duration: 1.12, stagger: 0.03, ease: 'secondaire-text-ease-2' }, 'start+=0.53');
    });

    criticalImagesLoaded(() => {
      this.imgWrap.remove();

      tl.play();
    });

    done();
  }
}

export default HouseTransition;
