import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

import { constants, instances } from '../../store';
import { intersectMinMax } from '../../utils/in-viewport';
import { inView } from '../../utils/inView';
import { vwPx } from '../../utils/math';

gsap.registerPlugin(CustomEase);
CustomEase.create('fast-in-slow-out-refined-ease', 'M0,0 C0.53,0 0,1 1,1 ');
CustomEase.create('smooth-in-out', 'M0,0 C0.38,0 0.32,1 1,1');

class Header {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-header');
    this.dom.content = this.dom.el.querySelector('.js-content');
    this.dom.imgWrap = this.dom.el.querySelector('.js-img-wrap');
    this.dom.nav = document.querySelector('.js-nav');

    this.dom.logo = this.dom.nav.querySelector('.js-logo');
    this.dom.langSwitch = this.dom.nav.querySelector('.js-lang-switch-nav');
    this.dom.langSwitchItems = this.dom.langSwitch.querySelectorAll('.js-lang-switch-item');
    this.dom.langIcon = this.dom.langSwitch.querySelector('.js-lang-icon');

    this.dom.backToTop = document.querySelector('.js-back-to-top');

    this.dom.videoPlayButton = this.dom.el.querySelector('.js-play-button');
    this.dom.app = document.querySelector('.js-app');
    this.dom.page = this.dom.app.querySelector('.page');

    this.state = {
      navAnim: false,
      navTheme: this.dom.nav.classList.contains('is-dark'),
      header: this.dom.el.dataset.theme === 'dark',
      observe: undefined,
      bttObserve: undefined
    };

    this.changeNav = this.state.navTheme && !this.state.header;

    if (constants.headerLayout === 'image-fluid' || constants.headerLayout === 'image-video') {
      this.dom.overlay = this.dom.el.querySelector('.js-overlay');
    }
  }

  render = () => {
    switch (constants.headerLayout) {
      case 'home': {
        const y = intersectMinMax(Math.abs(instances.scroll.state.last), this.dom.el, 0, vwPx(1440, 720), true);
        gsap.set(this.dom.imgWrap, { y: y });
        break;
      }

      case 'image-video': {
        const y = intersectMinMax(Math.abs(instances.scroll.state.last), this.dom.el, 0, vwPx(1440, 720), true);
        gsap.set(this.dom.imgWrap, { y: y });

        const opacity = intersectMinMax(Math.abs(instances.scroll.state.last), this.dom.el, 1, 0, true);
        gsap.set(this.dom.overlay, { opacity: opacity });
        break;
      }

      case 'image-fluid': {
        const y = intersectMinMax(Math.abs(instances.scroll.state.last), this.dom.el, 0, vwPx(1440, 720), true);
        gsap.set(this.dom.imgWrap, { y: y });

        const opacity = intersectMinMax(Math.abs(instances.scroll.state.last), this.dom.el, 1, 0, true);
        gsap.set(this.dom.overlay, { opacity: opacity });
        break;
      }

      case 'image-contained': {
        const y = intersectMinMax(Math.abs(instances.scroll.state.last), this.dom.el, 0, vwPx(1440, 360), true);
        gsap.set(this.dom.content, { y: y });
        break;
      }

      default:
        break;
    }
  }

  toggleNavColor = () => {
    this.state.observe = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          gsap.to(this.dom.logo, { fill: '#fff', duration: 0.2 });

          if (!constants.isDevice) {
            gsap.to(this.dom.langSwitchItems, { color: '#fff', duration: 0.2 });
            gsap.to(this.dom.langIcon, { stroke: '#fff', duration: 0.2 });
          }
        } else {
          gsap.to(this.dom.logo, { fill: '#111', duration: 0.2 });

          if (!constants.isDevice) {
            gsap.to(this.dom.langSwitchItems, { color: '#111', duration: 0.2 });
            gsap.to(this.dom.langIcon, { stroke: '#111', duration: 0.2 });
          }
        }
      });
    }, {
      rootMargin: `-${this.dom.logo.getBoundingClientRect().y}px`
    });

    this.state.observe.observe(this.dom.el);
  }

  toggleBackToTop = () => {
    this.state.bttObserve = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          constants.backToTop = false;
          gsap.to(this.dom.backToTop, { autoAlpha: 0, duration: 0.2 });
        } else {
          constants.backToTop = true;
          gsap.to(this.dom.backToTop, { autoAlpha: 1, duration: 0.2 });
        }
      });
    }, {
      rootMargin: `-${this.dom.logo.getBoundingClientRect().y}px`
    });

    this.state.bttObserve.observe(this.dom.el);
  }

  setVideoCache = () => {
    this.video = {
      src: this.dom.videoPlayButton.dataset.videosrc,
      playing: false,
      active: false
    };

    const bounceTL = gsap.timeline({ repeat: -1, repeatDelay: 0.5 });

    bounceTL
      .addLabel('start')
      .to(this.dom.buttonIcon, {
        scale: 0.8,
        duration: 0.43,
        ease: 'smooth-in-out'
      })

      .to(this.dom.buttonIcon, {
        scale: 1.23,
        duration: 0.35,
        ease: 'smooth-in-out'
      })

      .to(this.dom.buttonIcon, {
        scale: 1,
        duration: 0.18,
        ease: 'smooth-in-out'
      })

      .to(this.dom.buttonInnerRings, {
        duration: 0.7,
        scale: 1,
        ease: 'fast-in-slow-out-refined-ease',
        stagger: 0.3
      }, 'start+=0.57')

      .to(this.dom.buttonInnerRings, {
        duration: 0.7,
        opacity: 0,
        ease: 'fast-in-slow-out-refined-ease',
        stagger: 0.3
      }, 'start+=0.83');
  }

  createVideoPlayer = () => {
    this.video.playerWrap = document.createElement('div');
    this.video.playerWrap.classList.add('video-player__wrap');

    this.video.player = document.createElement('video');
    this.video.player.setAttribute('src', this.video.src);
    this.video.player.classList.add('video-player__player');
    this.video.player.setAttribute('data-cursor', 'pause');

    const button = document.createElement('button');
    button.classList.add('video-player__button');

    const w3 = 'http://www.w3.org/2000/svg';

    const closeIcon = document.createElementNS(w3, 'svg');
    closeIcon.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns', w3);
    closeIcon.classList.add('video-player__close-icon');

    closeIcon.setAttributeNS(null, 'viewBox', '0 0 24.042 24.042');

    const path1 = document.createElementNS(w3, 'path');
    path1.setAttributeNS(null, 'd', 'M.708 23.335L23.335.708');
    closeIcon.appendChild(path1);

    const path2 = document.createElementNS(w3, 'path');
    path2.setAttributeNS(null, 'd', 'M.708.708l22.627 22.627');
    closeIcon.appendChild(path2);

    button.appendChild(closeIcon);

    this.video.playerWrap.appendChild(button);

    this.video.player.play();

    setTimeout(() => {
      instances.cursor.initNewSections();
    });

    this.video.playerWrap.appendChild(this.video.player);

    this.dom.app.appendChild(this.video.playerWrap);

    this.video.playing = true;

    this.video.player.addEventListener('click', () => {
      if (this.video.playing) {
        this.video.player.pause();
        this.video.player.setAttribute('data-cursor', 'pause');
        this.video.playing = false;
      } else {
        this.video.player.play();
        this.video.player.setAttribute('data-cursor', 'play');
        this.video.playing = true;
      }
    });

    button.addEventListener('click', () => {
      this.destroyVideo();
    });
  }

  destroyVideo = () => {
    this.video.player.style.pointerEvents = 'none';

    gsap.to(this.video.playerWrap, {
      autoAlpha: 0,
      duration: 0.2,

      onComplete: () => {
        this.video.playerWrap.remove();
      }
    });
  }

  togglePlayer = () => {
    this.createVideoPlayer();

    this.video.player.style.pointerEvents = null;

    gsap.to(this.video.playerWrap, {
      autoAlpha: 1,
      duration: 0.2
    });

    this.video.player.addEventListener('ended', () => {
      this.destroyVideo();
    });
  }

  init() {
    inView(this.dom.el, this.render);

    const firstInterval = setInterval(() => {
      if (instances.scroll && constants.pageEntranceFinished) {
        clearInterval(firstInterval);

        this.toggleBackToTop();

        if (!this.dom.videoPlayButton) return;

        this.dom.buttonIcon = this.dom.videoPlayButton.querySelector('.js-button-icon');
        this.dom.buttonInnerRings = this.dom.videoPlayButton.querySelectorAll('.js-inner-ring');

        this.setVideoCache();

        this.dom.videoPlayButton.addEventListener('click', this.togglePlayer);
      }
    });

    if (!this.changeNav) return;

    const interval = setInterval(() => {
      if (instances.scroll && constants.pageEntranceFinished) {
        clearInterval(interval);

        this.toggleNavColor();
      }
    });
  }

  destroy() {
    instances.time.emitter.off('tick', this.render);

    this.state.bttObserve.unobserve(this.dom.el);

    this.dom.logo.style.color = null;
    this.dom.langIcon.style.stroke = null;

    this.dom.langSwitchItems.forEach((item) => {
      item.style.color = null;
    });

    if (this.dom.videoPlayButton) {
      this.dom.videoPlayButton.removeEventListener('click', this.togglePlayer);
    }

    if (!this.changeNav) return;
    this.state.observe.unobserve(this.dom.el);
  }
}

export default Header;
