import { gsap } from 'gsap';

class OverlayGrid {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;
    this.dom.items = this.dom.el.querySelectorAll('.js-item');
    this.dom.overlays = document.querySelectorAll('.js-grid-overlay');
  }

  setCache() {
    this.overlays = [];

    [...this.dom.items].forEach((el, index) => {
      this.overlays.push({
        el,
        overlay: this.dom.overlays[index],
      });
    });
  }

  toggle = (i) => {
    const current = this.overlays[i];

    const copy = current.overlay;

    document.body.appendChild(copy);

    gsap.to(copy, { autoAlpha: 1, duration: 0.2, ease: 'none' });

    copy.classList.add('is-active');

    const closeButton = current.overlay.querySelector('.js-close');

    closeButton.addEventListener('click', () => {
      gsap.to(copy, { autoAlpha: 0, duration: 0.2, ease: 'none', onComplete: () => { copy.remove(); } });
    });
  }

  addEventListeners() {
    [...this.dom.items].forEach((el, i) => { el.addEventListener('click', () => { this.toggle(i); }); });
  }

  removeEventListener() {
    [...this.dom.items].forEach((el, i) => { el.removeEventListener('click', () => { this.toggle(i); }); });
  }

  init() {
    this.setCache();

    this.addEventListeners();
  }

  destroy() {
    this.removeEventListener();
  }
}

export default OverlayGrid;
