import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

gsap.registerPlugin(CustomEase);

CustomEase.create('ease-smooth-in-out', '0.42,0.00,0.15,1.00');

import { constants, instances } from '../../store';

import { pxVw } from '../../utils/math';

class Subscribe {
  constructor(el) {
    this.dom = {};
    this.dom.el = el;
    this.dom.heading = this.dom.el.querySelector('.js-heading');
    this.dom.form = this.dom.el.querySelector('.js-form');
    this.dom.hidden = this.dom.el.querySelector('.js-hidden');
    this.dom.fields = this.dom.el.querySelectorAll('.js-field');

    this.state = {
      open: false
    };
  }

  setHeight = () => {
    if (!constants.isDevice) {
      this.dom.form.style.height = `${this.dom.heading.offsetHeight}px`;
    } else {
      this.dom.form.style.height = `${this.dom.form.offsetHeight}px`;
    }
  }

  openForm = () => {
    this.state.open = true;

    [...this.dom.fields].forEach(field => {
      field.style.marginBottom = (!constants.isDevice) ? `${pxVw(1440, 30)}vw` : `${pxVw(360, 30)}vw`;
    });

    gsap.to(this.dom.form, { height: 'auto', duration: 0.92, ease: 'ease-smooth-in-out' });
    gsap.to(this.dom.hidden, { y: 0, autoAlpha: 1, duration: 0.92, ease: 'ease-smooth-in-out', onComplete: () => {
      if (!constants.isDevice) instances.scroll.handleResize();
    } });
  }

  addListener() {
    this.dom.fields[0].addEventListener('click', this.openForm);

    if (!this.state.open) window.addEventListener('resize', this.setHeight);
  }

  removeListener() {
    this.dom.fields[0].removeEventListener('click', this.openForm);

    if (!this.state.open) window.removeEventListener('resize', this.setHeight);
  }

  init() {
    this.setHeight();
    this.addListener();

    this.dom.hidden.style.display = 'block';
    gsap.set(this.dom.hidden, { y: -30 });
  }

  destroy() {
    this.removeListener();
  }
}

export default Subscribe;
